import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronUp, X, EyeOff, Eye } from 'lucide-react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import OpeningHours from "../OpeningHours";
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Modal from '../Modal';
import axios from 'axios';
import { BusinessSignUpData } from '../../../api/BusinessSignup';
import { signUpBusiness } from '../../../api/BusinessSignup';
import ImageUploader from '../ImageUpload';
import { DateTime } from 'luxon';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface Category {
  id: string;     
  name: string;
}

interface Highlight {
  id: string;
  name: string;
}

interface MerchantPage1Props {
  categories: Category[];
  highlights: Highlight[];
}

interface FormData {
  businessName: string;
  categoryIds: string[];
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  description: string;
  highlightIds: string[];  // Change this to string[] | null to match BusinessSignUpData
  openingHours: {
    schedule: {
      [key: string]: {
        openTime?: DateTime | null;
        closeTime?: DateTime | null;
        closed: boolean;
      };
    };
  };
  profilePicture?: string | null;  // Change this to string | null to match BusinessSignUpData
  recaptchaResponse: string;
}

interface FormErrors {
  businessName?: string;
  categoryIds?: string;
  // phoneNumber?: string;
  // address?: string;
  // website?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  description?: string;
  highlightIds?: string;
  openingHours?: string;
  profilePicture?: string;
  termsAndPrivacy?: string;
}

const customStyles = `
  .react-tel-input .wide-dropdown {
    width: 350px !important;
    max-width: 900% !important;
  }
  .react-tel-input .form-control {
    width: 100% !important;
  }
`;

const MerchantPage1: React.FC<MerchantPage1Props> = ({ categories, highlights }) => {
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(true);
  const [isHighlightDropdownOpen, setIsHighlightDropdownOpen] = useState(true);
  const [errors, setErrors] = useState<FormErrors>({});
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [noWebsite, setNoWebsite] = useState(false);
  const [addressValid, setAddressValid] = useState(false);
  const [imageSize, setImageSize] = useState<string>('');
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const { executeRecaptcha } = useGoogleReCaptcha() || {};
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [fileSizeError, setFileSizeError] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    businessName: '',
    categoryIds: [],
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    description: '',  // Changed from businessDescription
    highlightIds: [],
    openingHours: {
      schedule: {
        "THURSDAY": {
          openTime: DateTime.fromObject({ hour: 6, minute: 0 }),
          closeTime: DateTime.fromObject({ hour: 18, minute: 0 }),
          closed: false
        },
        "MONDAY": {
          openTime: DateTime.fromObject({ hour: 6, minute: 0 }),
          closeTime: DateTime.fromObject({ hour: 18, minute: 0 }),
          closed: false
        },
        "WEDNESDAY": {
          openTime: DateTime.fromObject({ hour: 6, minute: 0 }),
          closeTime: DateTime.fromObject({ hour: 18, minute: 0 }),
          closed: false
        },
        "SUNDAY": {
          openTime: DateTime.fromObject({ hour: 6, minute: 0 }),
          closeTime: DateTime.fromObject({ hour: 18, minute: 0 }),
          closed: false
        },
        "FRIDAY": {
          openTime: DateTime.fromObject({ hour: 6, minute: 0 }),
          closeTime: DateTime.fromObject({ hour: 18, minute: 0 }),
          closed: false
        },
        "SATURDAY": {
          openTime: DateTime.fromObject({ hour: 6, minute: 0 }),
          closeTime: DateTime.fromObject({ hour: 18, minute: 0 }),
          closed: false
        },
        "TUESDAY": {
          openTime: DateTime.fromObject({ hour: 6, minute: 0 }),
          closeTime: DateTime.fromObject({ hour: 18, minute: 0 }),
          closed: false
        }
      }
    },
    recaptchaResponse: '',
  });

  const navigate = useNavigate();

  const searchPlaces = async (query: string) => {
    try {
      const response = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: query,
          format: 'json',
          addressdetails: 1,
          limit: 5
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error searching places:', error);
      return [];
    }
  };

  const validateForm = (data: FormData): FormErrors => {
    const errors: FormErrors = {};

    if (!data.businessName || data.businessName.length < 3 || data.businessName.length > 100) {
      errors.businessName = "Business name must be between 3 and 100 characters";
    }

    if (!data.categoryIds || data.categoryIds.length === 0 || data.categoryIds.length > 3) {
      errors.categoryIds = "Select between 1 and 3 categories";
    }

    if (!data.description || data.description.length > 500) {
      errors.description = "Description must not exceed 500 characters";
    }
    // if (!data.phoneNumber || !/^\+?[0-9]{10,15}$/.test(data.phoneNumber)) {
    //   errors.phoneNumber = "Invalid phone number format";
    // }

    // if (data.website && !/^https?:\/\/.+\..+/.test(data.website)) {
    //   errors.website = "Invalid website URL";
    // }

    if (!data.firstName || data.firstName.length < 2 || data.firstName.length > 50) {
      errors.firstName = "First name must be between 2 and 50 characters";
    }

    if (!data.lastName || data.lastName.length < 2 || data.lastName.length > 50) {
      errors.lastName = "Last name must be between 2 and 50 characters";
    }

    if (!data.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      errors.email = "Invalid email format";
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])(?=.*[^a-zA-Z0-9]).{8,}$/;
    if (!data.password) {
      errors.password = "Password is required";
    } else if (!passwordRegex.test(data.password)) {
      errors.password = "Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character";
    }

    if (!data.description || data.description.length > 500) {
      errors.description = "Description must not exceed 500 characters";
    }
    
    if (!data.highlightIds || data.highlightIds.length === 0) {
      errors.highlightIds = "Select at least one highlight";
    }

    return errors;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleWebsiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
      value = 'https://' + value;
    }
    setFormData(prev => ({ ...prev, website: value }));
  };

  const handleCategoryChange = (categoryId: string) => {
    setFormData(prevData => {
      if (prevData.categoryIds.includes(categoryId)) {
        return {
          ...prevData,
          categoryIds: prevData.categoryIds.filter(id => id !== categoryId)
        };
      } else if (prevData.categoryIds.length < 3) {
        return {
          ...prevData,
          categoryIds: [...prevData.categoryIds, categoryId]
        };
      } else {
        return prevData;
      }
    });
  };

  const handleHighlightChange = (highlightId: string) => {
    setFormData(prevData => {
      if (prevData.highlightIds.includes(highlightId)) {
        return {
          ...prevData,
          highlightIds: prevData.highlightIds.filter(id => id !== highlightId)
        };
      } else {
        return {
          ...prevData,
          highlightIds: [...prevData.highlightIds, highlightId]
        };
      }
    });
  };

  const handleTermsAccept = () => {
    setTermsAccepted(true);
    setIsTermsOpen(false);
    if (privacyAccepted) {
      setErrors(prev => ({ ...prev, termsAndPrivacy: undefined }));
    }
  };
  
  const handlePrivacyAccept = () => {
    setPrivacyAccepted(true);
    setIsPrivacyOpen(false);
    if (termsAccepted) {
      setErrors(prev => ({ ...prev, termsAndPrivacy: undefined }));
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setFormData(prev => ({ ...prev, password: newPassword }));
    setPasswordsMatch(confirmPassword === newPassword);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const confirmPwd = e.target.value;
    setConfirmPassword(confirmPwd);
    setPasswordsMatch(confirmPwd === formData.password);
  };

  const handleOpeningHoursChange = useCallback((newHours: any) => {
    const transformedHours: {
      [day: string]: {
        openTime: DateTime | null;
        closeTime: DateTime | null;
        closed: boolean;
      };
    } = {};
  
    let allDaysClosed = true;
    let invalidTimeRange = false;
  
    Object.entries(newHours).forEach(([day, hours]: [string, any]) => {
      transformedHours[day.toUpperCase()] = {
        openTime: hours.openTime || null,
        closeTime: hours.closeTime || null,
        closed: hours.isClosed
      };
  
      if (!hours.isClosed) {
        allDaysClosed = false;
        if (!allDaysClosed && hours.openTime && hours.closeTime && hours.openTime >= hours.closeTime) {
          invalidTimeRange = true;
        }
      }
    });
  
    setFormData(prev => ({ ...prev, openingHours: { schedule: transformedHours } }));
  
    if (allDaysClosed) {
      setErrors(prev => ({ ...prev, openingHours: "Business cannot be closed on all days" }));
    } else if (invalidTimeRange) {
      setErrors(prev => ({ ...prev, openingHours: "Close time must be after open time for all days" }));
    } else {
      setErrors(prev => ({ ...prev, openingHours: undefined }));
    }
  }, []);

  const handleProfilePictureChange = (imageData: string | undefined) => {
    setFormData(prev => ({ ...prev, profilePicture: imageData }));
  };

  const handleAutomaticLogin = async (email: string, password: string) => {
    try {
      if (!executeRecaptcha) {
        console.error('executeRecaptcha is not available');
        throw new Error('ReCAPTCHA is not available');
      }
  
      const recaptchaToken = await executeRecaptcha!('login');
      
      const response = await axios.post(`${API_BASE_URL}/v1/users/auth/login`, {
        email,
        password,
        recaptchaResponse: recaptchaToken,
      });
  
      if (response.status === 200 || response.status === 201) {
        const { token, userType } = response.data;
        localStorage.setItem('jwtToken', token);
        localStorage.setItem('userType', userType);
        navigate('/merchant-hub-home');
      }
    } catch (error) {
      console.error('Automatic login failed:', error);
      navigate('/login');
    }
  };

  useEffect(() => {
    if (!executeRecaptcha) {
      // // // console.log('Execute recaptcha not yet available');
      return;
    }
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      // // console.log('Execute recaptcha not yet available');
      return;
    }
    
    try {
      const token = await executeRecaptcha('signup');
      setFormData(prevData => ({
        ...prevData,
        recaptchaResponse: token
      }));
    } catch (error) {
      console.error('ReCAPTCHA verification failed:', error);
      // Handle the error appropriately
    }
  };

const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  setIsSubmitting(true);

  // Set a timer to automatically set isSubmitting to false after 5 seconds
  const submittingTimer = setTimeout(() => {
    setIsSubmitting(false);
  }, 10000);

  try {
    if (!termsAccepted || !privacyAccepted) {
      throw new Error("You must accept both the Terms of Service and Privacy Policy");
    }

    // Perform reCAPTCHA verification
    if (!executeRecaptcha) {
      throw new Error('Execute recaptcha not yet available');
    }
    const recaptchaToken = await executeRecaptcha('signup');
    if (!recaptchaToken) {
      throw new Error('Failed to execute reCAPTCHA');
    }

    // Update formData with the new reCAPTCHA token
    setFormData(prev => ({ ...prev, recaptchaResponse: recaptchaToken }));

    const newErrors = validateForm(formData);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      throw new Error("Action Failed. Please re-check your info.");
    }

    const response = await signUpBusiness({
      ...formData,
      recaptchaResponse: recaptchaToken
    });
    
    if (response && response.id) {
      // Handle successful signup
      await handleAutomaticLogin(formData.email, formData.password);
    } else {
      throw new Error('Unexpected signup response');
    }
  } catch (error) {
    // Handle all errors
    console.error('Signup error:', error);
    if (axios.isAxiosError(error) && error.response?.status === 400) {
      const responseData = error.response.data;
      if (responseData.validationErrors) {
        setValidationErrors(responseData.validationErrors);
      }
    }
    alert(error instanceof Error ? error.message : 'An unexpected error occurred. Please try again later.');
  } finally {
    clearTimeout(submittingTimer);
    setIsSubmitting(false);
  }
};

  const onRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  return (
    <form onSubmit={handleSubmit} className='text-start'>
      <section style={{ maxWidth: 'min(624px, 90vw)' }} className="flex flex-col">
        <style>{customStyles}</style>
        
        {/* Business Name */}
        <p className='text-main-teal font-semilight text-10xl mq780:text-19xl w-full text-center -mt-2'>Merchant Signup</p>
        <div className="mt-8 w-full text-lg font-medium leading-7 text-zinc-800 max-md:mt-10 max-md:max-w-full">What is the name of your business?</div>
        <div className="mt-6 w-full text-base leading-7 text-zinc-800 max-md:max-w-full">Business Name</div>
        <input
          type="text"
          name="businessName"
          placeholder="Enter here"
          maxLength={100}
          value={formData.businessName}
          onChange={handleInputChange}
          className="justify-center items-start px-3.5 py-1.5 mt-2 w-inherit text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500"
          required
        />
        {errors.businessName && <div className="text-red-500 text-sm mt-1">{errors.businessName}</div>}

        {/* Business Category */}
        <div className="mt-14 w-full text-lg font-medium leading-7 text-zinc-800">What type of business are you in?</div>
        <div className="mt-4 w-full text-base leading-7 text-zinc-800">Choose <strong>up to 3 categories</strong> that help your customers find you and let them know what your business is all about.</div>
        <div className="mt-5 w-full text-base leading-7 text-zinc-800">Business Category</div>
        <div
          className="flex items-center justify-center cursor-pointer mt-2 w-inherit rounded-lg border border-solid border-zinc-700 h-[30px]"
          onClick={() => setIsCategoryDropdownOpen(!isCategoryDropdownOpen)}
        >
          {isCategoryDropdownOpen ? <ChevronUp /> : <ChevronDown />}
        </div>
        {isCategoryDropdownOpen && (
          <div className="mt-2 border border-solid border-zinc-700 rounded-lg p-4">
            <div className="flex flex-col">
              {categories.map(category => (
                <label key={category.id} className='py-[3px] text-lg cursor-pointer'>
                  <input
                    className='mr-2 cursor-pointer'
                    type="checkbox"
                    checked={formData.categoryIds.includes(category.id)}
                    onChange={() => handleCategoryChange(category.id)}
                  />
                  {category.name}
                </label>
              ))}
            </div>
          </div>
        )}
        {errors.categoryIds && <div className="text-red-500 text-sm mt-1">{errors.categoryIds}</div>}

        {/* 
        Business Address
        <div className="mt-14 w-full text-lg font-medium leading-7 text-zinc-800">Where is your business located?</div>
        <div className="mt-6 w-inherit text-base leading-7 text-zinc-800">Business Address</div>
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          onPlaceSelected={(place: google.maps.places.PlaceResult) => {
            if (place.formatted_address) {
              setFormData(prev => ({
                ...prev,
                address: place.formatted_address || ''
              }));
              setAddressValid(true);
            } else {
              setAddressValid(false);
            }
          }}
          options={{
            types: ['address'],
          }}
          defaultValue={formData.address}
          className={`justify-center items-start px-4 py-1.5 mt-2 w-inherit text-base leading-7 rounded-lg border border-solid 'border-zinc-700' text-neutral-500`}
          placeholder="Enter your business address"
        />
        {errors.address && <div className="text-red-500 text-sm mt-1">{errors.address}</div>} 
        */}


        <div className="flex flex-col mt-14 gap-5 w-full max-w-2xl mx-auto box-border max-md:gap-0">
          <div className="text-lg font-medium leading-7">Let's set up your account information.</div>
          <div className="flex flex-row justify-between gap-5">
            <div className="flex flex-col w-full max-w-[48%] box-border">
              <div className="text-base leading-7 text-zinc-800">
                <div className="mt-6">First Name</div>
                <input
                  type="text"
                  name="firstName"
                  placeholder="e.g. Jane"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="h-10 px-4 py-0 mt-2 w-full max-w-full rounded-lg border border-solid border-zinc-700 text-zinc-800 text-base box-border"
                  autoComplete="given-name"
                  required
                />
                {errors.firstName && <div className="text-red-500 text-sm mt-1">{errors.firstName}</div>}
              </div>
            </div>
            <div className="flex flex-col w-full max-w-[48%] box-border">
              <div className="text-base leading-7 text-zinc-800">
                <div className="mt-6">Last Name</div>
                <input
                  type="text"
                  name="lastName"
                  placeholder="e.g. Doe"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="h-10 px-4 py-0 mt-2 w-full max-w-full rounded-lg border border-solid border-zinc-700 text-zinc-800 text-base box-border"
                  autoComplete="family-name"
                  required
                />
                {errors.lastName && <div className="text-red-500 text-sm mt-1">{errors.lastName}</div>}
              </div>
            </div>
          </div>
        </div>

        {/* Email */}
        <div className="mt-11 w-full text-base leading-7 text-zinc-800">Email</div>
        <input
          type="email"
          name="email"
          placeholder="e.g. janedoe@gmail.com"
          value={formData.email}
          onChange={handleInputChange}
          className="justify-center items-start px-4 py-1.5 mt-2 w-inherit text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500"
          autoComplete="email"
          required
        />
        {errors.email && <div className="text-red-500 text-sm mt-1">{errors.email}</div>}

        {/* Password */}
        <div className="mt-12 w-full text-base leading-7 text-zinc-800">Password</div>
          <div className="max-w-full relative pr-4">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            placeholder="Password"
            className="justify-center items-start pl-3.5 py-1.5 mt-2 w-full text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500"
            autoComplete="new-password"
            id="new-password"
            required
          />
            <span
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeOff className="stroke-black w-4 h-4 mt-2 ml-0" /> : <Eye className="stroke-black w-4 h-4 mt-2 ml-0" />}
            </span>
          </div>
          {errors.password && <div className="text-red-500 text-sm mt-1">{errors.password}</div>}
        <div className="hidden mt-3.5 w-inherit text-base leading-5 text-black">
          <div style={{ color: formData.password.length >= 8 ? 'green' : 'red' }}>• 8 characters long</div>
          <div style={{ color: /[!@#$%^&*()\\-_=+{};:,<.>?]/.test(formData.password) ? 'green' : 'red' }}>• Contain a special character (e.g., ! @ # ? )</div>
          <div style={{ color: /[A-Z]/.test(formData.password) && /[a-z]/.test(formData.password) ? 'green' : 'red' }}>• A mixture of both uppercase and lowercase letters</div>
          <div style={{ color: /[0-9]/.test(formData.password) ? 'green' : 'red' }}>• A mixture of letters and numbers</div>
        </div>

        {/* Confirm Password */}
        <div className="mt-12 w-full text-base leading-7 text-zinc-800">Confirm Password</div>
          <div className="max-w-full relative pr-4">
          <input
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Enter here"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            className="justify-center items-start pl-3.5 py-1.5 mt-2 w-full text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500"
            autoComplete="new-password"
            id="confirm-password"
            required
          />
          <span
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            {showConfirmPassword ? <EyeOff className="stroke-black w-4 h-4 mt-2 ml-0" /> : <Eye className="stroke-black w-4 h-4 mt-2 ml-0" />}
          </span>
          </div>
        {confirmPassword && (
          <div className={`mt-2 text-sm ${passwordsMatch ? 'text-green-500' : 'text-red-500'}`}>
            {passwordsMatch ? '' : 'Passwords do not match'}
          </div>
        )}

        {/* Business Description */}
        <div className="mt-14 w-full text-lg font-medium leading-7 text-zinc-800">How would you describe your business to your customers?</div>
        <div className="mt-5 w-full text-base leading-7 text-zinc-800">Write 2-3 sentences letting people know what your business is all about.</div>
        <div className="relative">
          <textarea
            name="description"
            maxLength={500}
            placeholder="Enter your description here"
            className="font-subhead h-40 px-5 max-w-[min(78vw,580px)] py-5 mt-2 w-full text-base leading-7 rounded-md border border-solid border-zinc-800 text-neutral-500 resize-none whitespace-pre-wrap"
            value={formData.description}
            onChange={handleInputChange}
          />
          <div className="absolute bottom-2 mr-2 mq500:mr-0 mt-4 right-5 text-sm text-gray-500">
            {formData.description.length}/500
          </div>
        </div>
        {errors.description && <div className="text-red-500 text-sm mt-1">{errors.description}</div>}

        {/* Business Hours */}
        <div className="mt-14 w-full text-lg font-medium leading-7 text-zinc-800">What are your business hours?</div>
        <div className="mt-5 w-full text-base leading-7 text-zinc-800">Let people know when they can visit you.</div>
        <OpeningHours
          // initialHours={formData.openingHours.schedule}
          onChange={handleOpeningHoursChange}
        />
        {errors.openingHours && <div className="text-red-500 text-sm mt-1">{errors.openingHours}</div>}

        {/* Business Highlights */}
        <div className="mt-14 w-full text-lg font-medium leading-7 text-zinc-800">Business Highlights</div>
        <div className="mt-5 w-full text-base leading-7 text-zinc-800">What makes your business unique? Choose all that apply!</div>
        <div 
          className="flex items-center justify-center cursor-pointer mt-2 w-inherit rounded-lg border border-solid border-zinc-700 h-[30px]"
          onClick={() => setIsHighlightDropdownOpen(!isHighlightDropdownOpen)}
        >
          {isHighlightDropdownOpen ? <ChevronUp /> : <ChevronDown />}
        </div>
        {isHighlightDropdownOpen && (
          <div className="mt-2 border border-solid border-zinc-700 rounded-lg p-4">
            <div className="flex flex-col">
              {highlights.map((highlight) => (
                <label key={highlight.id} className="cursor-pointer flex items-center mt-2">
                  <input
                    type="checkbox"
                    className="form-checkbox w-4 h-4 cursor-pointer"
                    checked={formData.highlightIds.includes(highlight.id)}
                    onChange={() => handleHighlightChange(highlight.id)}
                  />
                  <span className="ml-2">{highlight.name}</span>
                </label>
              ))}
            </div>
          </div>
        )}
        {errors.highlightIds && <div className="text-red-500 text-sm mt-1">{errors.highlightIds}</div>}

        {/* Profile Picture */}
        <div className="mt-14 w-full text-lg font-medium leading-7 text-zinc-800">Add your profile photo (optional)</div>
        <div className="mt-4 w-full text-base leading-7 text-zinc-800">Choose a photo that best represents your business for your profile photo. You can add more photos, videos and other content later!</div>
        <ImageUploader onImageChange={handleProfilePictureChange} />
        {errors.profilePicture && <div className="text-red-500 text-sm mt-1">{errors.profilePicture}</div>}

        {/* Terms and Privacy */}
        <div className="mt-14 w-full text-base flex flex-wrap leading-7 490px:flex-row">
          <label className="flex items-center justify-center mt-[2px]">
            <input
              onClick={() => setIsTermsOpen(true)} 
              type="checkbox"
              className="cursor-pointer form-checkbox w-4 h-4 mr-5 pb-2"
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              required
            />
          </label>
          <span className="whitespace-nowrap text-zinc-800 pr-2">
            I agree to the{" "}
          </span>
          <span 
            onClick={() => setIsTermsOpen(true)} 
            className="whitespace-nowrap cursor-pointer text-blue-500 underline pr-2"
          >
            Terms of Service
          </span>
        </div>
        <div className="mt-2 w-full text-base flex flex-wrap leading-7 490px:flex-row">
          <label className="flex items-center justify-center mt-[2px]">
            <input
              onClick={() => setIsPrivacyOpen(true)} 
              type="checkbox"
              className="cursor-pointer form-checkbox w-4 h-4 mr-5 pb-2"
              checked={privacyAccepted}
              onChange={() => setPrivacyAccepted(!privacyAccepted)}
              required
            />
          </label>
          <span className="whitespace-nowrap text-zinc-800 pr-2">
            I agree to the{" "}
          </span>
          <span 
            onClick={() => setIsPrivacyOpen(true)} 
            className="whitespace-nowrap cursor-pointer text-blue-500 underline"
          >
            Privacy Policy
          </span>
        </div>
        {errors.termsAndPrivacy && <div className="text-red-500 text-sm mt-1">{errors.termsAndPrivacy}</div>}

        {/* Terms and Privacy Modals */}
        <Modal 
          isOpen={isTermsOpen} 
          onClose={() => {setIsTermsOpen(false), setTermsAccepted(false)}} 
          onAccept={handleTermsAccept}
          title="Terms of Service"
        >
        <main className="relative mq780:mx-10 px-6 font-subhead mt-8 max-w-[650px] text-start items-start justify-start 580px:mx-5">
          <strong>Effective Date: Jul 5th 2024</strong><br />
          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-2 ">Introduction</strong><br />
          Welcome to DoYouWeekend! These Terms of Service govern your use of our website and services. By accessing or using our site, you agree to comply with and be bound by these terms. If you do not agree to these terms, please do not use our site.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">User Responsibilities</strong><br />
          As a user of our site, you agree to provide accurate and complete information about yourself and to update this information as necessary. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or password.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Prohibited Activities</strong><br />
          You agree not to use the site for any unlawful or prohibited purpose. You agree not to:

          <ul>
            <li>Engage in any activity that interferes with or disrupts the site or the servers and networks that host the site.</li>
            <li>Use any automated means to access the site for any purpose without our express written permission.</li>
            <li>Attempt to gain unauthorized access to any portion or feature of the site, or any other systems or networks connected to the site.</li>
            <li>Engage in any fraudulent activity or impersonate any person or entity.</li>
          </ul>

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Intellectual Property</strong><br />
          All content on this site, including but not limited to text, graphics, logos, and images, is the property of DoYouWeekend or its content suppliers and is protected by copyright and other intellectual property laws. You agree not to reproduce, distribute, or create derivative works based on the content without our express written permission.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Termination</strong><br />
          We may terminate or suspend your access to the site without notice if you breach these terms or for any other reason. Upon termination, you must cease all use of the site and any content obtained from the site.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Disclaimers and Limitation of Liability</strong><br />
          The site is provided "as is" and "as available" without warranties of any kind, either express or implied. DoYouWeekend does not warrant that the site will be uninterrupted or error-free. To the fullest extent permitted by law, DoYouWeekend disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Governing Law</strong><br />
          These terms shall be governed by and construed in accordance with the laws of the State of Florida, without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located in Florida for the resolution of any disputes arising out of or relating to these terms or the site.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Changes to These Terms</strong><br />
          We may update these Terms of Service from time to time. If we make changes, we will notify you by revising the date at the top of this document and, in some cases, we may provide additional notice. We encourage you to review these terms periodically to stay informed about our practices.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Contact Us</strong><br />
          If you have questions or comments about these Terms of Service, please contact us at:<br />
          <br />
          DoYouWeekend<br />
          7901 4TH ST N, STE 300<br />
          ST. PETERSBURG, FL 33702 <br />
          rich@doyouweekend.com<br />
          +1 (518) 416-2744<br />
        </main>
      </Modal>

        <Modal 
          isOpen={isPrivacyOpen} 
          onClose={() => {setIsPrivacyOpen(false), setPrivacyAccepted(false)}} 
          onAccept={handlePrivacyAccept} 
          title="Privacy Policy"
        >
        <main className="relative mq780:mx-10 px-6 font-subhead mt-8 max-w-[650px] text-start items-start justify-start">
          <strong>Effective Date: Jul 5th 2024</strong><br />
          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-2 ">Introduction</strong><br />
          Welcome to DoYouWeekend! We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.doyouweekend.com or use our services. Please read this Privacy Policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Information We Collect</strong><br />
          We may collect information about you in a variety of ways. The information we may collect on the Site includes:

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Personal Data</strong><br />
          Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Derivative Data</strong><br />
          Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Financial Data</strong><br />
          Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor, STRIPE, and you are encouraged to review their privacy policy and contact them directly for responses to your questions.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Data From Social Networks</strong><br />
          User information from social networking sites, such as Facebook, Google+, Instagram, including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts, if you connect your account to such social networks.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Mobile Device Data</strong><br />
          Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you access the Site from a mobile device.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Use of Your Information</strong><br />
          Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:
          <ul>
            <li>Create and manage your account.</li>
            <li>Process your transactions and send you related information, including purchase confirmations and invoices.</li>
            <li> Manage your orders and provide customer support.</li>
            <li>Personalize and improve our services.</li>
            <li>Develop new products, services, and features.</li>
            <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the Site, and for marketing and promotional purposes.</li>
            <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
            <li>Analyze trends and monitor usage and activities in connection with the Site</li>
            <li>Comply with legal obligations and resolve any disputes we may have with any of our users and enforce our agreements.</li>
          </ul>

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Disclosure of Your Information</strong><br />
          We may share information we have collected about you in certain situations. Your information may be disclosed as follows:

          <br />
          <p className="text-start items-start flex justify-start m-0 mt-6 ">By Law or to Protect Rights</p><br />
          If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.

          <br />
          <p className="text-start items-start flex justify-start m-0 mt-6 ">Third-Party Service Providers</p><br />
          We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.

          <br />
          <p className="text-start items-start flex justify-start m-0 mt-6 ">Business Transfers</p><br />
          We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.

          <br />
          <p className="text-start items-start flex justify-start m-0 mt-6 ">Affiliates</p><br />
          We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.

          <br />
          <p className="text-start items-start flex justify-start m-0 mt-6 ">Other Third Parties</p><br />
          We may share your information with advertisers and investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Security of Your Information</strong><br />
          We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Policy for Children</strong><br />
          We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible. If you believe we might have any information from or about a child under 13, please contact us at the support email provided below.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Changes to This Privacy Policy</strong><br />
          We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.

          <br />
          <strong className="text-start items-start flex justify-start m-0 mt-6 ">Contact Us</strong><br />
          If you have questions or comments about this Privacy Policy, please contact us at:<br />
          <br />
          DoYouWeekend<br />
          7901 4TH ST N, STE 300<br />
          ST. PETERSBURG, FL 33702 <br />
          rich@doyouweekend.com<br />
          +1 (518) 416-2744<br />
        </main>
      </Modal>

      {/* Submit Button */}
      <div className="relative mt-10">
      <button
        type="submit"
        disabled={isSubmitting}
        className={`w-full justify-center p-2 text-base font-medium tracking-tight text-center text-white rounded-lg ${
          isSubmitting ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-900 cursor-pointer'
        }`}
      >
        {isSubmitting ? (
          <>
            <span className="mr-2">Creating your account</span>
            <span className="animate-spin inline-block">&#8987;</span>
          </>
        ) : (
          'Sign Up'
        )}
      </button>
      </div>

      {/* Validation Errors */}
      {Object.keys(validationErrors).length > 0 && (
        <div className="mt-4 text-red-500">
          {Object.entries(validationErrors).map(([key, value]) => (
            <p key={key}>{value}</p>
          ))}
        </div>
      )}
    </section>
  </form>
  );
}

export default MerchantPage1;