import React, { FunctionComponent, useState, useEffect, useCallback } from "react";
import axios from 'axios';
import ErrorBoundary from "../../components/Merchant/ErrorBoundary";
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import MerchantHubSideNav from "../../components/Merchant/MerchantHubSideNav";
import { useNavigate } from "react-router-dom";
import EditButton from "../../components/General/EditButton";
import ImageUploaderMultiple from "../../components/Merchant/ImageUploadMultiple";
import 'react-phone-input-2/lib/style.css';
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import OpeningHours from "../../components/Merchant/OpeningHours";
import { ChevronDown, ChevronUp, X } from 'lucide-react';
import { ImageSlider } from "../../components/General/ImageSlider";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import { DateTime } from "luxon";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

const customStyles = `
  .react-tel-input .form-control {
    width: 106% !important;
    height: auto !important;
    padding: 0.375rem 0.875rem !important;
    padding-left: 48px !important;
    font-size: 1rem !important;
    line-height: 1.75rem !important;
    border-radius: 0.5rem !important;
    border: 1px solid #3f3f46 !important;
    color: #737373 !important;
    background-color: transparent !important;
  }
  .react-tel-input .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0.5rem 0 0 0.5rem !important;
    padding-left: 5px !important;
  }
  .react-tel-input .selected-flag {
    background-color: transparent !important;
    border-radius: 0.5rem 0 0 0.5rem !important;
  }
  .react-tel-input .country-list {
    width: 900% !important;
    max-width: 800% !important;
    border-radius: 0.5rem !important;
    border: 1px solid #3f3f46 !important;
    margin-top: 0.25rem !important;
  }
`;

  const categories = [
    'Food & Drinks',
    'Tours & Excursions',
    'Nightlife',
    'Outdoors / Nature',
    'Shopping & Fashion',
    'Museums & Culture',
    'Water Activities',
    'Sports',
    'Entertainment & Leisure',
    'Classes & Workshops',
  ];  

interface Category {
  id: string;
  name: string;
}

interface Highlight {
  id: string;
  name: string;
}

interface MerchantProfile {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  status: string;
  userType: string;
  preferences: {
    wantsPromotionalEmails: boolean;
  };
  createdAt: string;
  businessName: string;
  highlights: Highlight[]; //add highlight interface
  categories: Category[]; //add category interface
  description: string;
  displayImageReferences: string[];
  profilePictureReference: string;
  openingHours: {
    schedule: {
      [key: string]: {
        openTime: DateTime | null;
        closeTime: DateTime | null;
        closed: boolean;
      };
    };
  };
  address: {
    line1: string,
    line2: string,
    city: string,
    state: string,
    postalCode: string,
    country: string
  };
  offerIds: string[];
  promotionIds: string[];
  highlightIds: string[];
  categoryIds: string[];
}

const BusinessInfo: FunctionComponent = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [infoEditMode, setInfoEditMode] = useState(true);
  const [photoEditMode, setPhotoEditMode] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [dragOver, setDragOver] = useState(false);
  const [merchantProfile, setMerchantProfile] = useState<MerchantProfile | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [newEmail, setNewEmail] = useState("");
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const navigateWithWarning = useUnsavedChangesWarning(hasUnsavedChanges);
  const navigate = useNavigate();
  const handleNavigation = (path: string) => {
    navigateWithWarning(path);
  };
  useUnsavedChangesWarning(hasUnsavedChanges);

  const fetchCategories = async () => {
    try {
      const response = await axios.get<Category[]>(`${API_BASE_URL}/v1/catalog/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error('Failed to fetch categories:', error);
    }
  };

  useEffect(() => {
    fetchMerchantProfile();
    fetchCategories();
  }, []);

  const fetchMerchantProfile = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/v1/users/merchant/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
      
      // Convert opening hours to DateTime objects
      const convertedOpeningHours = convertToDateTime(response.data.openingHours.schedule);
      const updatedProfile = {
        ...response.data,
        openingHours: {
          schedule: convertedOpeningHours
        }
      };
      
      setMerchantProfile(updatedProfile);
      setSelectedCategories(updatedProfile.categories || []);
      
      // Handle image references
      let images = [];
      if (updatedProfile.displayImageReferences && updatedProfile.displayImageReferences.length > 0) {
        images = updatedProfile.displayImageReferences;
      } else if (updatedProfile.profilePictureReference) {
        images = [updatedProfile.profilePictureReference];
      }
      setUploadedImages(images);
    } catch (error) {
      console.error('Error fetching merchant profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateProfile = (updates: Partial<MerchantProfile>) => {
    setMerchantProfile(prev => {
      if (!prev) return null;
      const updatedProfile = { ...prev, ...updates };

      // Update highlightIds and categoryIds
      if (updates.highlights) {
        updatedProfile.highlightIds = updates.highlights.map(h => h.id);
      }
      if (updates.categories) {
        updatedProfile.categoryIds = updates.categories.map(c => c.id);
      }

      setHasUnsavedChanges(true);
      setIsFormChanged(true);
      setIsFormValid(validateForm(updatedProfile));
      
      // If displayImageReferences are updated, also update uploadedImages
      if (updates.displayImageReferences) {
        setUploadedImages(updates.displayImageReferences);
      }
      
      return updatedProfile;
    });
  };

  //Handle beforeunload Event
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    if (merchantProfile) {
      setIsFormValid(validateForm(merchantProfile));
    }
  }, [merchantProfile]);

  const handleInfoEditClick = () => {
    setInfoEditMode(!infoEditMode);
  };

  const handlePhotoEditMode = () => {
    setPhotoEditMode(!photoEditMode);
  };

  const onBusinessInfoClick = () => {
    navigate("/merchant-hub-deals");
  };

  const validateForm = (profile: MerchantProfile) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const conditions = {
      businessName: profile.businessName.trim().length > 0 && profile.businessName.length <= 100,
      email: emailRegex.test(newEmail || profile.email),
      highlights: profile.highlights.length > 0 && profile.highlights.every(id => id !== null),
      categories: profile.categories.length > 0 && profile.categories.every(cat => cat !== null),
      description: profile.description.trim().length > 0 && profile.description.length <= 500,
      openingHours: profile.openingHours !== null && Object.keys(profile.openingHours.schedule).length > 0
    };
  
    return Object.values(conditions).every(condition => condition === true);
  };

  const handleCheckboxChange = (category: Category) => {
    setSelectedCategories((prevSelected) => {
      if (prevSelected.some(cat => cat.id === category.id)) {
        return prevSelected.filter((cat) => cat.id !== category.id);
      } else if (prevSelected.length < 3) {
        return [...prevSelected, category];
      } else {
        return prevSelected;
      }
    });
  };

  const handleOpeningHoursChange = useCallback((newHours: Record<string, { openTime: DateTime | null; closeTime: DateTime | null; isClosed: boolean }>) => {
    setMerchantProfile(prev => {
      if (!prev) return null;
      const updatedSchedule: MerchantProfile['openingHours']['schedule'] = {};
      Object.entries(newHours).forEach(([day, hours]) => {
        updatedSchedule[day] = {
          openTime: hours.openTime,
          closeTime: hours.closeTime,
          closed: hours.isClosed
        };
      });
      return {
        ...prev,
        openingHours: {
          schedule: updatedSchedule
        }
      };
    });
  }, []);

  const deleteImage = (index: number) => {
    const updatedImages = uploadedImages.filter((_, i) => i !== index);
    setUploadedImages(updatedImages);
    updateProfile({ displayImageReferences: updatedImages });
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => setDragOver(false);

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setDragOver(false);
    handleFiles(e.dataTransfer.files);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleFiles(e.target.files);
    }
  };

  const handleFiles = (files: FileList) => {
    const newImages = Array.from(files).map(file => URL.createObjectURL(file));
    setUploadedImages(prevImages => [...prevImages, ...newImages]);
    updateProfile({ displayImageReferences: [...uploadedImages, ...newImages] });
  };

  const handleSaveChanges = async () => {
    if (!merchantProfile) return;
  
    setIsSaving(true);
    setApiError(null);
    setValidationErrors(null);
    
    try {
      const formData = new FormData();
  
      // Create a new object with only the properties we want to send to the API
      const apiProfile = {
        id: merchantProfile.id,
        email: merchantProfile.email,
        firstName: merchantProfile.firstName,
        lastName: merchantProfile.lastName,
        status: merchantProfile.status,
        userType: merchantProfile.userType,
        preferences: merchantProfile.preferences,
        businessName: merchantProfile.businessName,
        description: merchantProfile.description,
        openingHours: {
          schedule: formatOpeningHours(merchantProfile.openingHours.schedule)
        },
        address: merchantProfile.address,
        highlightIds: merchantProfile.highlights.map(h => h.id),
        categoryIds: merchantProfile.categories.map(c => c.id)
      };
  
      // Convert the merchant data to a Blob with JSON content type
      const merchantBlob = new Blob([JSON.stringify(apiProfile)], {
        type: 'application/json'
      });
      formData.append('merchant', merchantBlob, 'merchant.json');
  
      // Append profile picture if it exists
      if (merchantProfile.profilePictureReference) {
        const profilePictureBlob = await fetch(merchantProfile.profilePictureReference).then(r => r.blob());
        formData.append('profilePicture', profilePictureBlob, 'profile_picture.jpg');
      }
  
      // Append display images
      for (let i = 0; i < uploadedImages.length; i++) {
        const imageBlob = await fetch(uploadedImages[i]).then(r => r.blob());
        formData.append(`displayImages`, imageBlob, `display_image_${i}.jpg`);
      }
  
      const response = await axios.put(
        `${API_BASE_URL}/v1/users/merchant/profile`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );
  
      if (response.status === 200) {
        setIsFormChanged(false);
        setHasChanges(false);
  
        // If email has changed, initiate email change process
        if (newEmail && newEmail !== merchantProfile.email) {
          await handleEmailChangeRequest();
        } else {
          // If no email change, update the profile state
          setMerchantProfile(prevProfile => ({
            ...prevProfile!,
            ...apiProfile,
          }));
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 400 && error.response.data.validationErrors) {
          setValidationErrors(error.response.data.validationErrors);
        } else {
          setApiError(error.response.data.message || 'An error occurred while saving changes');
        }
      } else {
        setApiError('An unexpected error occurred');
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleEmailChangeRequest = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.post(`${API_BASE_URL}/v1/users/email-change/request`, {
        currentEmail: merchantProfile?.email,
        newEmail: newEmail
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (response.status === 200) {
        // Show a success message or handle the email change confirmation process
        setApiError('Email change request sent. Please check your new email for confirmation.');
      }
    } catch (error) {
      console.error('Failed to request email change:', error);
      setApiError('Failed to request email change. Please try again.');
    }
  };

  const UploadArea = ({ isMain = false }: { isMain?: boolean }) => (
    <div
      className={`flex justify-center items-center p-4 rounded-md border-2 border-solid max-w-[min(600px, 85vw)] ${
        dragOver ? 'border-blue-500' : 'border-zinc-300'
      } ${isMain ? 'h-72' : 'h-24 w-24'}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="flex flex-col items-center">
        <img
          src="/camera.svg"
          alt="Upload icon"
          className="w-8 h-8 mb-2"
        />
        <div className="text-sm text-center">
          {isMain ? "Drag & drop a photo here or click to upload" : "Upload"}
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="hidden"
          id={isMain ? "mainFileInput" : "additionalFileInput"}
        />
        <label htmlFor={isMain ? "mainFileInput" : "additionalFileInput"} className="mt-2 cursor-pointer text-blue-500 underline text-sm">
          Browse files
        </label>
      </div>
    </div>
  );

  const formatOpeningHours = (schedule: MerchantProfile['openingHours']['schedule']) => {
    const formattedSchedule: Record<string, any> = {};
    Object.entries(schedule).forEach(([day, hours]) => {
      if (!hours) {
        formattedSchedule[day] = { closed: true };
      } else if (hours.closed) {
        formattedSchedule[day] = { closed: true };
      } else if (hours.openTime && hours.closeTime) {
        formattedSchedule[day] = {
          openTime: hours.openTime.toISO(),
          closeTime: hours.closeTime.toISO(),
          closed: false
        };
      } else {
        formattedSchedule[day] = { closed: true };
      }
    });
    return formattedSchedule;
  };

  const convertHours = (hours: Record<string, { openTime: DateTime | null; closeTime: DateTime | null; isClosed: boolean }>) => {
    return Object.entries(hours).reduce((acc, [day, { openTime, closeTime, isClosed }]) => {
      acc[day] = {
        openTime: openTime,
        closeTime: closeTime,
        closed: isClosed
      };
      return acc;
    }, {} as Record<string, { openTime: DateTime | null; closeTime: DateTime | null; closed: boolean }>);
  };

  const convertToDateTime = (hours: { [key: string]: { openTime: string | null; closeTime: string | null; closed: boolean } }) => {
    return Object.entries(hours).reduce((acc, [day, { openTime, closeTime, closed }]) => {
      acc[day] = {
        openTime: openTime ? DateTime.fromISO(openTime) : null,
        closeTime: closeTime ? DateTime.fromISO(closeTime) : null,
        closed
      };
      return acc;
    }, {} as { [key: string]: { openTime: DateTime | null; closeTime: DateTime | null; closed: boolean } });
  };

  return (
    <ErrorBoundary>
    <div className="flex flex-col">
      <MerchantHubHeader />
      <main className="flex-1 flex min-h-screen font-subhead flex-row">
        <MerchantHubSideNav 
          onNavigate={navigateWithWarning}
          selected="Business Info"/>

        {/* Main Content */}  
        {isLoading ? (
          <div className="font-subhead p-8 text-3xl">Loading...</div>
        ) : merchantProfile ? (
        <div className="flex flex-col gap-2 mq780:items-start bg-white w-full max-w-full grow px-4 mq600:px-8">
          <div className="flex flex-row gap-20 580px:gap-10 mt-10 w-full justify-between max-w-[530px]">
            <h1 className="text-29xl 580px:text-11xl whitespace-nowrap leading-[50.04px] tracking-tight text-zinc-800 font-bold flex flex-col">Business Info</h1> 
            <div className="hidden mt-14 580px:mt-9"><EditButton onButtonClick={() => setInfoEditMode(!infoEditMode)} /></div>
          </div>

          {/* Name */}  
          <div className="flex flex-col pb-8">
            <div className="mt-0 w-full text-mini leading-7 text-zinc-800 max-md:max-w-full pb-0 font-semibold">Business Name</div>
            <input
              type="text"
              name="businessName"
              placeholder="Enter here"
              maxLength={100}
              value={merchantProfile?.businessName || ''}
              onChange={(e) => updateProfile({ businessName: e.target.value })}
              className="justify-center items-start px-3.5 py-1.5 mt-1 max-w-[500px] w-[85vw] mq780:w-[50vw] text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500"
              disabled={!infoEditMode}
              required
            />
          </div>

          {/* Business Email */}
          <div className="flex flex-col pb-8">
            <div className="mt-0 w-full text-mini leading-7 text-zinc-800 max-md:max-w-full pb-0 font-semibold">Business Email</div>
            <input
              type="email"
              name="businessEmail"
              placeholder="Enter business email"
              value={newEmail || merchantProfile?.email || ''}
              onChange={(e) => {
                  setNewEmail(e.target.value);
                  updateProfile({ email: e.target.value });
                  setHasChanges(true);
              }}
              className="justify-center items-start px-3.5 py-1.5 mt-1 max-w-[500px] w-[85vw] mq780:w-[50vw] text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500"
              required
            />
          </div>

          <div className="flex flex-col pb-8 hidden">
            <div className="w-inherit text-mini leading-7 text-zinc-800 font-semibold">Business Address</div>
            {infoEditMode ? (
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                defaultValue={merchantProfile?.address ? `${merchantProfile.address.line1}, ${merchantProfile.address.city}, ${merchantProfile.address.state} ${merchantProfile.address.postalCode}, ${merchantProfile.address.country}` : ''}
                onPlaceSelected={(place) => {
                  if (place.address_components) {
                    const addressComponents = place.address_components;
                    const newAddress = {
                      line1: `${addressComponents.find(c => c.types.includes('street_number'))?.long_name || ''} ${addressComponents.find(c => c.types.includes('route'))?.long_name || ''}`,
                      line2: '',
                      city: addressComponents.find(c => c.types.includes('locality'))?.long_name || '',
                      state: addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.long_name || '',
                      postalCode: addressComponents.find(c => c.types.includes('postal_code'))?.long_name || '',
                      country: addressComponents.find(c => c.types.includes('country'))?.long_name || '',
                    };
                    setMerchantProfile(prev => ({ ...prev!, address: newAddress }));
                  }
                }}
                options={{
                  types: ['address'],
                }}
                className={`justify-center items-start px-3.5 py-1.5 max-w-[500px] w-[85vw] mq780:w-[50vw] text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500`}
              />
            ) : (
              <input
                type="text"
                value={merchantProfile?.address ? `${merchantProfile.address.line1}, ${merchantProfile.address.city}, ${merchantProfile.address.state} ${merchantProfile.address.postalCode}, ${merchantProfile.address.country}` : ''}
                className="justify-center items-start px-3.5 py-1.5 max-w-[500px] w-[85vw] mq780:w-[50vw] text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500"
                disabled
              />
            )}
          </div>

          {/* Opening Hours */}
          <div className="w-full pb-0 text pt-5 leading-7 text-zinc-800 font-semibold">Business Hours</div>
          {merchantProfile.openingHours && merchantProfile.openingHours.schedule ? (
            <OpeningHours
              initialHours={merchantProfile.openingHours.schedule}
              onChange={(newHours) => updateProfile({ openingHours: { schedule: convertHours(newHours) } })}
              disabled={!infoEditMode}
            />
          ) : (
            <div className="font-subhead text-red-500">No opening hours available</div>
          )}

          {/* Categories */}
          <div className="pt-10 w-full text-sm leading-7 text-zinc-800 font-semibold">Business Categories</div>
          <div
            className="flex items-center justify-between px-3.5 py-1.5 max-w-[500px] w-[85vw] mq780:w-[50vw] text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500 cursor-pointer"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <span>Categories</span>
            <span className="flex items-center">
              {isDropdownOpen ? (
                <ChevronUp className="w-5 h-5 ml-2" />
              ) : (
                <ChevronDown className="w-5 h-5 ml-2" />
              )}
            </span>
          </div>
          {isDropdownOpen && (
            <div className="mt-2 max-w-[500px] w-[85vw] mq780:w-[50vw] border border-solid border-zinc-700 rounded-lg p-3.5">
              <div className="flex flex-col">
              {categories.map((category) => (
                <label key={category.id} className="flex items-center mt-2 cursor-pointer">
                  <input
                    type="checkbox"
                    className="form-checkbox cursor-pointer w-4 h-4"
                    checked={merchantProfile.categories.some(cat => cat.id === category.id)}
                    onChange={() => {
                      const updatedCategories = merchantProfile.categories.some(cat => cat.id === category.id)
                        ? merchantProfile.categories.filter(cat => cat.id !== category.id)
                        : [...merchantProfile.categories, category];
                      updateProfile({ categories: updatedCategories });
                    }}
                    disabled={!infoEditMode || (!merchantProfile.categories.some(cat => cat.id === category.id) && merchantProfile.categories.length >= 3)}
                  />
                  <span className="ml-2">{category.name}</span>
                </label>
              ))}
              </div>
            </div>
          )}

          {/* Description */}  
          <p className="mt-10 m-0 font-semibold w-full text-sm leading-7 text-zinc-800 max-md:max-w-full">
            Business Bio
          </p>
          <div className="mb-16 relative max-w-[500px] w-[88%] mq780:w-[50vw]">
            <textarea
              name="description"
              maxLength={500}
              placeholder="Enter your description here"
              className="font-subhead h-40 px-5 py-5 mt-2 w-full text-base leading-7 rounded-md border border-solid border-zinc-800 text-neutral-500 resize-none whitespace-pre-wrap"
              value={merchantProfile?.description || ''}
              onChange={(e) => updateProfile({ description: e.target.value })}
              disabled={!infoEditMode}
            />
            <div className="absolute bottom-2 mr-2 mq500:mr-0 mt-4 right-5 text-sm text-gray-500">
              {merchantProfile?.description?.length || 0}/500
            </div>
          </div>

          {/* Photos */}   
          <div className="pb-20">
            <div className="flex flex-row gap-20 580px:gap-10 mt-10 w-full justify-between">
              <h1 className="text-29xl 580px:text-11xl whitespace-nowrap leading-[50.04px] tracking-tight text-zinc-800 font-bold flex flex-col">Photos</h1> 
            </div>
            <div className="justify-center items-start py-1.5 max-w-[530px] mq780:w-[50vw] text-base leading-7 rounded-lg text-neutral-500">
              <ImageSlider images={uploadedImages.map(url => ({ url }))} />
              {infoEditMode && 
                <ImageUploaderMultiple
                  onImageChange={(imageData) => {
                    if (imageData) {
                      setUploadedImages(prev => [...prev, imageData]);
                      updateProfile({ displayImageReferences: [...uploadedImages, imageData] });
                    }
                  }}
                  maxSize={15 * 1024 * 1024} // 15MB
                  currentImages={uploadedImages}
                  onDeleteImage={(index) => {
                    const updatedImages = uploadedImages.filter((_, i) => i !== index);
                    setUploadedImages(updatedImages);
                    updateProfile({ displayImageReferences: updatedImages });
                  }}
                  maxImages={5}
                />
              }
            </div>
          {infoEditMode && (
            <div className="w-full flex flex-col items-center mt-8 mb-8">
              <button
                className={`px-6 py-2 rounded-lg ${
                  (isFormChanged && isFormValid && !isSaving)
                    ? 'bg-main-teal text-white cursor-pointer'
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
                disabled={!isFormChanged || !isFormValid || isSaving}
                onClick={handleSaveChanges}
              >
                {isSaving ? 'Saving changes...' : 'Save Changes'}
              </button>
              {validationErrors && (
                <div className="mt-4 text-red-500">
                  <h3>Validation Errors:</h3>
                  <ul>
                    {Object.entries(validationErrors).map(([field, error]) => (
                      <li key={field}>{`${field}: ${error}`}</li>
                    ))}
                  </ul>
                </div>
              )}
              {apiError && (
                <div className="mt-4 text-red-500">
                  {apiError}
                </div>
              )}
            </div>
          )}    
          </div>  
        </div>
        ) : (
          <div className="font-subhead p-8 text-3xl">Failed to load merchant profile</div>
        )}
        </main>
        <footer className="mt-auto">
          <Footer
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
          />
        </footer>  
      </div>
    </ErrorBoundary>
  );
};

export default BusinessInfo;