import { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import DynamicTitleWrapper from "./components/General/DynamicTitleWrapper";
import useAxiosWithTokenRefresh from "./hooks/useAxiosWithTokenRefresh";
import { MerchantSignUpProvider } from "./components/Merchant/MerchantSignup/MerchantSignupContext";
import ProtectedRoute from './routeProtection/ProtectedRoute';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Helmet } from "react-helmet-async";
import { AxiosProvider } from './contexts/AxiosContext';

import HomeScreen from "./pages/User/HomeScreen";
import H21SearchResultsMapOpen from "./pages/User/H21SearchResultsMapOpen";
import H41LogInForm from "./pages/User/H41LogInForm";
import H42SignUpForm from "./pages/User/H42SignUpForm";
import H51DetailsPage from "./pages/User/H51DetailsPage";
import H61ItineraryPage from "./pages/User/H61ItineraryPage";
import H62LikesPage from "./pages/User/H62LikesPage";
import H71Quiz from "./pages/Quiz/H71Quiz";
import H72Quiz from "./pages/Quiz/H72Quiz";
import H73Quiz from "./pages/Quiz/H73Quiz";
import H75Quiz from "./pages/Quiz/H75Quiz";
import H76Quiz from "./pages/Quiz/H76Quiz";
import H77QuizPage from "./pages/Quiz/H77Quiz";
import QuizResult from "./pages/Quiz/QuizResults";
import BusinessPage from "./pages/User/Account/BusinessPage";

import Account from "./pages/User/Account/Account";
import Bookings from "./pages/User/Account/Bookings"
import Profile from "./pages/User/Account/Profile"
import Notifications from "./pages/User/Account/Notifications";
import Preferences from "./pages/User/Account/Preferences";
import AccountInfo from "./pages/User/Account/AccountInfo";
import Payments from "./pages/User/Account/Payments";
import OrderSuccess from "./pages/User/OrderSuccess";
import NotificationSettings from "./pages/User/Account/NotificationSettings";
import CheckoutPage from "./pages/User/Checkout";
import CloseAccount from "./pages/User/Account/CloseAccount";
import Privacy from "./pages/User/Account/Privacy";
import SuggestFeature from "./pages/User/Account/SuggestFeature";
import UserFAQ from "./pages/User/UserFAQ";
import ContactSupport from "./pages/User/Account/ContactSupport";
import ConfirmEmail from "./pages/User/Account/ConfirmEmail";
import ResetPassword from "./pages/User/Account/PasswordReset";
import EmailChange from "./pages/User/EmailChange";
import OAuthCallback from "./components/User/OAuthCallback";

import M00MerchantHub from "./pages/Merchant/M00MerchantHub";
import M01MerchantPage from "./pages/Merchant/M01MerchantPage";
import M03MerchantPage from "./pages/Merchant/M03MerchantPage";
import MerchantHubHome from "./pages/Merchant/MerchantHubHome";
import MerchantHubDeals from "./pages/Merchant/MerchantHubDeals";
import MerchantHubPost1 from "./pages/Merchant/MerchantHubPost1";
import MerchantHubPost2 from "./pages/Merchant/MerchantHubPost2";
import MerchantHubPost3 from "./pages/Merchant/MerchantHubPost3";
import MerchantHubFAQ from "./pages/Merchant/MerchantHubFAQ";
import MerchantLogIn from "./pages/Merchant/MerchantLogIn";
import MerchantTermsOfService from "./pages/Merchant/MerchantTermsOfService";
import MerchantPrivacyPolicy from "./pages/Merchant/MerchantPrivacyPolicy";
import BusinessInfo from "./pages/Merchant/MerchantHubBusinessInfo";
import MerchantAccount from "./pages/Merchant/MerchantAccount";
import Analytics from "./pages/Merchant/Analytics";
import ScanQRCode from "./pages/Merchant/ScanQRCode";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const axiosInstance = useAxiosWithTokenRefresh();

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  const routes = [
    { path: "/", element: <HomeScreen />, title: "DoYouWeekend | Find things to do, explore and book exciting local experiences and deals in your area with DoYouWeekend", description: "Local experiences and deals in Fort Myers and Orlando - Find things to do, explore and book exciting local experiences and deals in your area with DoYouWeekend. Find the perfect weekend activities and adventures." },
    { path: "/offer/:offerId", 
      element: <DynamicTitleWrapper 
        getTitle={() => document.title}
        description="Get all the information you need about this exciting local experience. Read reviews, check availability, and book your next adventure."
        path="/offer/:offerId"
      >
        <H51DetailsPage />
      </DynamicTitleWrapper>,
    },
    { path: "/business/:merchantId", 
      element: <DynamicTitleWrapper 
        getTitle={() => document.title}
        description="View business profile and offers/promotions for a specific merchant on DoYouWeekend"
        path="/business/:merchantId"
      >
        <BusinessPage />
      </DynamicTitleWrapper>,
    },
    { path: "/map", element: <H21SearchResultsMapOpen />, title: "Interactive Experience Map | DoYouWeekend", description: "Browse our interactive map to find local offers, experiences, and deals near you. Discover hidden gems in your neighborhood with DoYouWeekend." },
    { path: "/login", element: <H41LogInForm />, title: "Log In to Your Account | DoYouWeekend", description: "Access your DoYouWeekend account to manage bookings, view personalized offers, and plan your next local adventure." },
    { path: "/signup", element: <H42SignUpForm />, title: "Join DoYouWeekend | Create Your Account", description: "Sign up for DoYouWeekend to unlock exclusive local deals, personalized recommendations, and seamless booking experiences." },
    { path: "/oauth2/redirect", element: <OAuthCallback />, title: "Processing OAuth Login | DoYouWeekend", description: "Completing your sign-in process with DoYouWeekend." },
    { path: "/user-faq", element: <UserFAQ />, title: "Frequently Asked Questions | DoYouWeekend", description: "Find answers to common questions about using DoYouWeekend, booking experiences, and managing your account." },
    { path: "/merchant-hub-faq", element: <MerchantHubFAQ />, title: "Merchant FAQ | DoYouWeekend", description: "Find answers to common questions about managing your business on DoYouWeekend." },
    { path: "/terms-of-service", element: <MerchantTermsOfService />, title: "Terms of Service | DoYouWeekend", description: "Read our terms of service to understand your rights and responsibilities when using DoYouWeekend." },
    { path: "/privacy-policy", element: <MerchantPrivacyPolicy />, title: "Privacy Policy | DoYouWeekend", description: "Learn how DoYouWeekend protects your personal information and respects your privacy." },
    { path: "/password-reset/:token", element: <ResetPassword />, title: "Reset Your Password | DoYouWeekend", description: "Securely reset your DoYouWeekend account password to regain access to your account." },
    { path: "/confirm-email/:token", element: <ConfirmEmail />, title: "Confirm Your Email | DoYouWeekend", description: "Verify your email address to activate your DoYouWeekend account and start exploring local experiences." },
    { path: "/support", element: <ContactSupport />, title: "Contact Support | DoYouWeekend", description: "Verify your email address to activate your DoYouWeekend account and start exploring local experiences." },
    { path: "/checkout/:orderId", element: <CheckoutPage />, title: "Secure Checkout | DoYouWeekend", description: "Complete your booking securely and easily for your chosen local experience on DoYouWeekend." },
  ];

  const protectedCustomerRoutes = [
    { path: "/account", element: <Account />, title: "My Account | DoYouWeekend", description: "Manage your DoYouWeekend account, view bookings, and update your preferences for a personalized local experience." },
    { path: "/account/bookings", element: <Bookings />, title: "My Bookings | DoYouWeekend", description: "View and manage all your booked experiences and upcoming adventures on DoYouWeekend." },
    { path: "/account/profile", element: <Profile />, title: "My Profile | DoYouWeekend", description: "Update your personal information and customize your DoYouWeekend profile to enhance your experience." },
    { path: "/account/notifications", element: <Notifications />, title: "My Notifications | DoYouWeekend", description: "Stay informed about your bookings, favorite experiences, and exclusive offers on DoYouWeekend." },
    { path: "/account/preferences", element: <Preferences />, title: "My Preferences | DoYouWeekend", description: "Customize your experience preferences to receive tailored recommendations on DoYouWeekend." },
    { path: "/account/account-info", element: <AccountInfo />, title: "Account Information | DoYouWeekend", description: "Review and update your account details to keep your DoYouWeekend profile current." },
    { path: "/account/payments", element: <Payments />, title: "Payment Methods | DoYouWeekend", description: "Manage your payment options for quick and secure bookings on DoYouWeekend." },
    { path: "/account/notification-settings", element: <NotificationSettings />, title: "Notification Settings | DoYouWeekend", description: "Control how and when you receive updates about your DoYouWeekend experiences and offers." },
    { path: "/account/close-account", element: <CloseAccount />, title: "Close Account | DoYouWeekend", description: "Manage your account closure process on DoYouWeekend. We're sad to see you go!" },
    { path: "/account/privacy", element: <Privacy />, title: "Privacy Settings | DoYouWeekend", description: "Manage your privacy preferences and control your data on DoYouWeekend." },
    { path: "/account/support", element: <ContactSupport />, title: "Customer Support | DoYouWeekend", description: "Get help with your DoYouWeekend account, bookings, or general inquiries from our support team." },
    { path: "/email-change/:token", element: <EmailChange />, title: "Confirm Email Change | DoYouWeekend", description: "Confirm your new email address for your DoYouWeekend account."},
    { path: "/order/success/:orderId", element: <OrderSuccess />, title: "Booking Confirmed | DoYouWeekend", description: "Your experience has been successfully booked. Get ready for your next adventure with DoYouWeekend!" },
    { path: "/suggest-feature", element: <SuggestFeature />, title: "Suggest a Feature | DoYouWeekend", description: "Help us improve DoYouWeekend by suggesting new features or improvements. We value your input!" },
    { path: "/itineraries", element: <H61ItineraryPage />, title: "My Itineraries | DoYouWeekend", description: "Plan and organize your local adventures with DoYouWeekend's itinerary planner." },
    { path: "/itineraries/:itineraryId", element: <H61ItineraryPage />, title: "Itinerary Details | DoYouWeekend", description: "View and edit your custom itinerary for an amazing local experience with DoYouWeekend." },
    { path: "/likes", element: <H62LikesPage />, title: "My Liked Experiences | DoYouWeekend", description: "Browse your favorite local experiences and deals saved on DoYouWeekend." },
    { path: "/h71-quiz-page", element: <H71Quiz />, title: "Personalization Quiz | DoYouWeekend", description: "Take our quiz to discover personalized local experiences tailored just for you on DoYouWeekend." },
    { path: "/h72-quiz-page", element: <H72Quiz />, title: "Personalization Quiz | DoYouWeekend", description: "Continue your journey to personalized local experiences with DoYouWeekend." },
    { path: "/h73-quiz-page", element: <H73Quiz />, title: "Personalization Quiz | DoYouWeekend", description: "Help us understand your preferences for the perfect local experiences on DoYouWeekend." },
    { path: "/h75-quiz-page", element: <H75Quiz />, title: "Personalization Quiz | DoYouWeekend", description: "Tell us more about your interests for tailored local experience recommendations on DoYouWeekend." },
    { path: "/h76-quiz-page", element: <H76Quiz />, title: "Personalization Quiz | DoYouWeekend", description: "Almost done! Final questions for your personalized DoYouWeekend experience." },
    { path: "/h77-quiz-page", element: <H77QuizPage />, title: "Personalization Quiz | DoYouWeekend", description: "Complete your personalization profile to unlock curated local experiences on DoYouWeekend." },
    { path: "/quiz-results", element: <QuizResult />, title: "Your Personalized Results | DoYouWeekend", description: "Discover your perfect local experiences based on your preferences with DoYouWeekend." },
  ];

  const protectedMerchantRoutes = [
    { path: "/merchant-hub-home", element: <MerchantHubHome />, title: "Merchant Dashboard | DoYouWeekend", description: "Access your DoYouWeekend merchant dashboard to manage listings, view analytics, and grow your local business." },
    { path: "/merchant-hub-deals", element: <MerchantHubDeals />, title: "Manage Deals | DoYouWeekend Merchant", description: "Create and manage your deals and offers to attract more customers on DoYouWeekend." },
    { path: "/merchant-hub-analytics", element: <Analytics />, title: "Business Analytics | DoYouWeekend Merchant", description: "Gain insights into your performance and customer engagement on DoYouWeekend." },
    { path: "/merchant-business-info", element: <BusinessInfo />, title: "Business Information | DoYouWeekend Merchant", description: "Update and manage your business details to showcase your offerings on DoYouWeekend." },
    { path: "/merchant-hub-account", element: <MerchantAccount />, title: "Merchant Account | DoYouWeekend", description: "Manage your merchant account settings and preferences on DoYouWeekend." },
    { path: "/merchant-hub-post-1/:offerId?", element: <MerchantHubPost1 />, title: "Create New Offer | DoYouWeekend Merchant", description: "Start creating a new exciting offer for your customers on DoYouWeekend." },
    { path: "/merchant-hub-post-2/:offerId?", element: <MerchantHubPost2 />, title: "Offer Details | DoYouWeekend Merchant", description: "Add detailed information to your new offer on DoYouWeekend." },
    { path: "/merchant-hub-post-3/:offerId?", element: <MerchantHubPost3 />, title: "Publish Offer | DoYouWeekend Merchant", description: "Review and publish your new offer to reach customers on DoYouWeekend." },
    { path: "/merchant-scan-qr-code", element: <ScanQRCode />, title: "Scan QR Code | DoYouWeekend Merchant", description: "Easily validate customer bookings by scanning QR codes on DoYouWeekend." },
  ];

  const merchantSignupRoutes = [
    { path: "/merchant-hub", element: <M00MerchantHub />, title: "Join as a Merchant | DoYouWeekend", description: "Partner with DoYouWeekend to showcase your local experiences and reach more customers. Sign up as a merchant today!" },
    { path: "/merchant-signup", element: <M01MerchantPage />, title: "Merchant Sign Up | DoYouWeekend", description: "Create your merchant account on DoYouWeekend and start growing your local business." },
    { path: "/merchant-verification", element: <M03MerchantPage />, title: "Merchant Verification | DoYouWeekend", description: "Complete the verification process to activate your DoYouWeekend merchant account." },
    { path: "/merchant-login", element: <MerchantLogIn />, title: "Merchant Login | DoYouWeekend", description: "Access your DoYouWeekend merchant account to manage your listings and bookings." },
  ];

  return (
    <AxiosProvider value={axiosInstance}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH2_GOOGLE_CLIENT_ID || ''}>
    <MerchantSignUpProvider>
      <Routes>
        {routes.map(({ path, element, title, description }) => (
          <Route
            key={path}
            path={path}
            element={
              <>
                <Helmet>
                  <title>{title}</title>
                  <meta name="description" content={description} />
                  <link rel="canonical" href={`https://doyouweekend.com${path}`} />
                  <meta property="og:title" content={title} />
                  <meta property="og:description" content={description} />
                  <meta property="og:url" content={`https://doyouweekend.com${path}`} />
                  <meta name="twitter:title" content={title} />
                  <meta name="twitter:description" content={description} />
                </Helmet>
                {element}
              </>
            }
          />
        ))}

        {/* Add the redirect route here, before your protected routes */}
        <Route path="/faqs" element={<Navigate to="/user-faq" replace />} />
        <Route path="/search" element={<Navigate to="/map" replace />} />
        
        {protectedCustomerRoutes.map(({ path, element, title, description }) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute allowedRole="CUSTOMER">
                <Helmet>
                  <title>{title}</title>
                  <meta name="description" content={description} />
                  <link rel="canonical" href={`https://doyouweekend.com${path}`} />
                  <meta property="og:title" content={title} />
                  <meta property="og:description" content={description} />
                  <meta property="og:url" content={`https://doyouweekend.com${path}`} />
                  <meta name="twitter:title" content={title} />
                  <meta name="twitter:description" content={description} />
                </Helmet>
                {element}
              </ProtectedRoute>
            }
          />
        ))}

        {protectedMerchantRoutes.map(({ path, element, title, description }) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute allowedRole="MERCHANT">
                <Helmet>
                  <title>{title}</title>
                  <meta name="description" content={description} />
                  <link rel="canonical" href={`https://doyouweekend.com${path}`} />
                  <meta property="og:title" content={title} />
                  <meta property="og:description" content={description} />
                  <meta property="og:url" content={`https://doyouweekend.com${path}`} />
                  <meta name="twitter:title" content={title} />
                  <meta name="twitter:description" content={description} />
                </Helmet>
                {element}
              </ProtectedRoute>
            }
          />
        ))}

        {merchantSignupRoutes.map(({ path, element, title, description }) => (
          <Route
            key={path}
            path={path}
            element={
              <>
                <Helmet>
                  <title>{title}</title>
                  <meta name="description" content={description} />
                  <link rel="canonical" href={`https://doyouweekend.com${path}`} />
                  <meta property="og:title" content={title} />
                  <meta property="og:description" content={description} />
                  <meta property="og:url" content={`https://doyouweekend.com${path}`} />
                  <meta name="twitter:title" content={title} />
                  <meta name="twitter:description" content={description} />
                </Helmet>
                {element}
              </>
            }
          />
        ))}
      </Routes>
    </MerchantSignUpProvider>
    </GoogleOAuthProvider>
    </AxiosProvider>
  );
}

export default App;