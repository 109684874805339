import { FunctionComponent, useCallback, useState, useEffect, useRef } from "react";
import QuizBlock from "../../components/User/QuizBlock";
import Caroussel from "../../components/User/Caroussel";
import CarousselCloseBy from "../../components/User/CarousselCloseBy";
import Rounder from "../../components/General/Rounder";
import UserFooter from "../../components/User/UserFooter";
import Header from "../../components/User/Header";
import { useNavigate } from "react-router-dom";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import Map from "../../components/General/Map";
import MobileRounder from "../../components/Mobile/MobileRounder";
import BusinessRounder from "../../components/General/BusinessRounder";
import BusinessRounderMobile from "../../components/Mobile/BusinessRounderMobile";
import axios from 'axios';
import { useAxios } from "../../contexts/AxiosContext";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

//Ghetto Solution
const EXCLUDED_BUSINESS_NAMES = [
  "Test",
  "John's Pizza",
  "John‘s Pizza",
  "John's Pizzaa",
  "Leonid‘s biz",
  "Leonid's biz",
  "There the error is",
  "Unexpected Runtime Exception",
  "DoYouWeekend"
];

interface Offer {
  id: string;
  name: string;
  price: number;
  latitude: number;
  longitude: number;
  categories: string[];
  images: string[];
  promotionType?: string;
  voucherCap?: number;
  percentageDiscount?: number;
  minimumPurchaseAmount?: number;
  discountValue?: number;
}

interface ApiOffer {
  id: string;
  name: string;
  price: number;
  locations: {
    latitude: number;
    longitude: number;
  }[];
  categories: {
    name: string;
  }[];
  displayImageReferences: string[];
}

interface Business {
  id: string;
  businessName: string;
  categories: { id: string; name: string }[];
  description: string;
  profilePictureReference: string;
}


async function fetchPromotions(offerIds: string[]): Promise<any> {
  try {
    const token = localStorage.getItem('jwtToken');
    // if (!token) {
    //   throw new Error('No JWT token found');
    // }

    const response = await axios.get(`${API_BASE_URL}/v1/promotions/by-offers`, {
      params: { offerIds: offerIds.join(',') },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching promotions:', error);
    return [];
  }
}

const HomeScreen: FunctionComponent = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 1050;
  const [trendingOffers, setTrendingOffers] = useState<Offer[]>([]);
  const [dealsForYouOffers, setDealsForYouOffers] = useState<Offer[]>([]);
  const [closestToYouOffers, setClosestToYouOffers] = useState<Offer[]>([]);
  const [isLoadingTrending, setIsLoadingTrending] = useState<boolean>(true);
  const [isLoadingDealsForYou, setIsLoadingDealsForYou] = useState<boolean>(true);
  const [isLoadingClosestToYou, setIsLoadingClosestToYou] = useState<boolean>(true);
  const [errorTrending, setErrorTrending] = useState<string | null>(null);
  const [errorDealsForYou, setErrorDealsForYou] = useState<string | null>(null);
  const [errorClosestToYou, setErrorClosestToYou] = useState<string | null>(null);
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [isLoadingBusinesses, setIsLoadingBusinesses] = useState<boolean>(true);
  const [errorBusinesses, setErrorBusinesses] = useState<string | null>(null);
  const navigate = useNavigate();
  const axiosInstance = useAxios();

  async function fetchCategory(category: string): Promise<any> {
    const defaultLocation = {
      //Fort Myers location
      latitude: 26.6406,
      longitude: -81.8723
    };
    
    if (!axiosInstance) {
      throw new Error('Axios instance not available');
    }
  
    const userLatitude = localStorage.getItem('userLatitude') || defaultLocation.latitude;
    const userLongitude = localStorage.getItem('userLongitude') || defaultLocation.longitude;
  
    try {
      switch (category) {
        case 'DealsForYou':
          if (localStorage.getItem('userType') == 'MERCHANT')
            return;
          const token = localStorage.getItem('jwtToken');
          if (!token) {
            throw new Error('No JWT token found');
          }
          const response = await axiosInstance.get(`${API_BASE_URL}/v1/recommendations/personalized`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          return response.data;
  
        case 'Trending':
          const trendingResponse = await axios.post(`${API_BASE_URL}/v1/recommendations/trending`, {
            location: {
              latitude: userLatitude,
              longitude: userLongitude
            }
          });
          return trendingResponse.data;
  
        case 'ClosestToYou':
          const nearbyResponse = await axios.post(`${API_BASE_URL}/v1/recommendations/nearby`, {
              latitude: userLatitude,
              longitude: userLongitude
          });
          return nearbyResponse.data;
  
        case 'Eat':
          const eatResponse = await axios.post(`${API_BASE_URL}/v1/recommendations/search`, 
            'Restaurant',
            {
              headers: {
                'Content-Type': 'text/plain'
              }
            }
          );
          return eatResponse.data;
  
        case 'Events':
          const eventsResponse = await axios.post(`${API_BASE_URL}/v1/recommendations/search`, 
            'Event',
            {
              headers: {
                'Content-Type': 'text/plain'
              }
            }
          );
          return eventsResponse.data;
  
        default:
          throw new Error('Invalid category');
      }
    } catch (error) {
      console.error('Error fetching category data:', error);
      throw error;
    }
  }

  async function fetchData() {
    try {
      setIsLoadingTrending(true);
      setIsLoadingClosestToYou(true);
      setErrorTrending(null);
      setErrorClosestToYou(null);
  
      const [trending, closestToYou] = await Promise.all([
        fetchCategory('Trending'),
        fetchCategory('ClosestToYou')
      ]);
  
      let mappedTrending = mapApiOffersToOffers(trending);
      let mappedClosestToYou = mapApiOffersToOffers(closestToYou);
  
      const allOfferIds = [...mappedTrending, ...mappedClosestToYou].map(offer => offer.id);
      const promotions = await fetchPromotions(allOfferIds);
  
      mappedTrending = applyPromotionsToOffers(mappedTrending, promotions);
      mappedClosestToYou = applyPromotionsToOffers(mappedClosestToYou, promotions);
  
      setTrendingOffers(mappedTrending);
      setClosestToYouOffers(mappedClosestToYou);
  
      const jwtToken = localStorage.getItem("jwtToken");
      if (jwtToken) {
        setIsLoadingDealsForYou(true);
        setErrorDealsForYou(null);
        try {
          const dealsForYou = await fetchCategory('DealsForYou');
          let mappedDealsForYou = mapApiOffersToOffers(dealsForYou);
          mappedDealsForYou = applyPromotionsToOffers(mappedDealsForYou, promotions);
          setDealsForYouOffers(mappedDealsForYou);
        } catch (error) {
          console.error('Error fetching deals for you:', error);
          if (error instanceof Error) {
            setErrorDealsForYou(error.message);
          }
        } finally {
          setIsLoadingDealsForYou(false);
        }
      }
  
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error instanceof Error) {
        if (error.message.includes('Trending')) {
          setErrorTrending(error.message);
        } else if (error.message.includes('ClosestToYou')) {
          setErrorClosestToYou(error.message);
        }
      }
    } finally {
      setIsLoadingTrending(false);
      setIsLoadingClosestToYou(false);
    }
  }

  async function fetchBusinesses() {
    try {
      setIsLoadingBusinesses(true);
      setErrorBusinesses(null);
      const response = await axios.get(`${API_BASE_URL}/v1/merchants/recent`);
      const mappedBusinesses = response.data.content
        .filter((business: Business) => !EXCLUDED_BUSINESS_NAMES.includes(business.businessName))
        .map((business: any) => ({
          id: business.id,
          businessName: business.businessName,
          categories: business.categories,
          description: business.description,
          profilePictureReference: business.profilePictureReference,
        }));
      setBusinesses(mappedBusinesses);
    } catch (error) {
      console.error('Error fetching businesses:', error);
      if (error instanceof Error) {
        setErrorBusinesses(error.message);
      }
    } finally {
      setIsLoadingBusinesses(false);
    }
  }

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
  
    fetchData();
    fetchBusinesses();
  }, []);

  const mapApiOffersToOffers = (apiOffers: ApiOffer[]): Offer[] => {
    return apiOffers.map(apiOffer => ({
      id: apiOffer.id,
      name: apiOffer.name,
      images: apiOffer.displayImageReferences,
      price: apiOffer.price,
      latitude: apiOffer.locations[0]?.latitude ?? 0,
      longitude: apiOffer.locations[0]?.longitude ?? 0,
      categories: apiOffer.categories.map(cat => cat.name),
    }));
  };

  function applyPromotionsToOffers(offers: Offer[], promotions: any[]): Offer[] {
    return offers.map(offer => {
      const promotion = promotions.find(promo => promo.offerIds.includes(offer.id));
      if (promotion) {
        return {
          ...offer,
          promotionType: promotion.type,
          percentageDiscount: promotion.type === 'PERCENTAGE_DISCOUNT' ? promotion.discountValue : undefined,
          minimumPurchaseAmount: promotion.type === 'BUY_X_GET_Y_FREE' ? promotion.minimumPurchaseAmount : undefined,
          discountValue: promotion.type === 'BUY_X_GET_Y_FREE' ? promotion.discountValue : undefined,
        };
      }
      return offer;
    });
  }

  const onQuickAddContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='background']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onFilterClick = () => {
    navigate("/map");
  };

  const userLatitude = parseFloat(localStorage.getItem('userLatitude') || "26.6406");
  const userLongitude = parseFloat(localStorage.getItem('userLongitude') || "-81.8723");

  return (
    <>
    {!isMobile ? ( 
    // Desktop
    <div className="hidden mq1050:flex w-full relative bg-white overflow-hidden flex flex-col justify-start px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead">
      <Header />
      <div className=""><QuizBlock /></div>
      {localStorage.getItem("jwtToken") && localStorage.getItem("userType") == "CUSTOMER" && <section className="self-stretch bg-white-100 flex flex-row items-center justify-center relative max-w-full text-center text-29xl text-main-teal font-subhead">
        <div className="flex-1 flex flex-col items-center justify-start pt-0 px-0 pb-[67px] box-border gap-[26px] max-w-full text-left text-mini-2 mq450:pb-[29px] mq450:box-border mq1050:pb-11 mq1050:box-border">
          <div className="self-stretch flex flex-col items-center justify-center pt-[0px] px-[20px] box-border relative gap-[20px] max-w-full z-[1] text-center text-29xl mq750:gap-[40px_81px] mq750:pt-[60px] mq750:pr-[22px] mq750:pb-[29px] mq750:box-border mq450:gap-[20px_81px]">
          {isLoadingDealsForYou ? (
              <div className="h-[200px] text-center flex text-11xl items-center justify-center">Loading deals for you...</div>
            ) : errorDealsForYou ? (
              <div>{errorDealsForYou}</div>
            ) : (
              <Caroussel
                title="Deals For You"
                filterButton={true}
                activities={
                  dealsForYouOffers.map((offer) => (
                    <Rounder
                      key={`forYou-${offer.id}`}
                      id={offer.id}
                      images={offer.images}
                      name={offer.name}
                      categories={offer.categories}
                      price={offer.price}
                      longitude={offer.longitude}
                      latitude={offer.latitude}
                      promotionType={offer.promotionType}
                      percentageDiscount={offer.percentageDiscount}
                      buy_x={offer.minimumPurchaseAmount}
                      get_y={offer.discountValue}
                    />
                  ))
                }
              />
            )}
          </div>
        </div>
      </section>}
      <section className="self-stretch bg-zinc-100 flex flex-row items-start justify-start relative max-w-full text-center text-29xl text-main-teal font-subhead">
        <div className="flex-1 flex flex-col items-end justify-start pt-0 px-0 pb-[67px] box-border gap-[26px] max-w-full text-left text-mini-2 mq450:pb-[29px] mq450:box-border mq1050:pb-11 mq1050:box-border">
            <div className="self-stretch flex flex-col items-center justify-start gap-[19px] max-w-full text-left text-base text-copy">
            <Caroussel
              title="Trending Deals"
              activities={
                trendingOffers.map((offer) => (
                  <Rounder
                    key={`trending-${offer.id}`}
                    id={offer.id}
                    images={offer.images}
                    name={offer.name}
                    categories={offer.categories}
                    price={offer.price}
                    longitude={offer.longitude}
                    latitude={offer.latitude}
                    promotionType={offer.promotionType}
                    percentageDiscount={offer.percentageDiscount}
                    buy_x={offer.minimumPurchaseAmount}
                    get_y={offer.discountValue}
                  />
                ))
              }
            />
          </div>
        </div>
      </section>
      {/* Desktop Businesses Section */}
      <section className="self-stretch bg-white-100 flex flex-row items-start justify-start relative max-w-full text-center text-29xl text-main-teal font-subhead">
        <div className="flex-1 flex flex-col items-end justify-start pt-0 px-0 pb-[67px] box-border gap-[26px] max-w-full text-left text-mini-2 mq450:pb-[29px] mq450:box-border mq1050:pb-11 mq1050:box-border">
          <div className="self-stretch flex flex-col items-center justify-start gap-[19px] max-w-full text-left text-base text-copy">
            {isLoadingBusinesses ? (
              <div className="h-[200px] text-center flex text-11xl items-center justify-center">Loading businesses...</div>
            ) : errorBusinesses ? (
              <div>{errorBusinesses}</div>
            ) : (
              <Caroussel
                title="Businesses"
                activities={
                  businesses
                    .filter(business => !EXCLUDED_BUSINESS_NAMES.includes(business.businessName))
                    .map((business) => (
                      <BusinessRounder
                        key={`business-${business.id}`}
                        id={business.id}
                        businessName={business.businessName}
                        categories={business.categories}
                        description={business.description}
                        profilePictureReference={business.profilePictureReference}
                      />
                    ))
                }
              />
            )}
          </div>
        </div>
      </section>
      <section className="self-stretch pb-20 bg-white-100 flex flex-row items-center justify-center relative max-w-full text-center text-29xl text-main-teal font-subhead">
          <div className="text-29xl text-main-teal font-subhead flex flex-col items-center justify-center gap-[0px] mq750:gap-[22px_45px]">
            <h1 className="leading-[72px] mb-3 text-29xl text-main-teal font-subhead inline-block mq750:text-19xl mq750:leading-[58px] mq450:text-10xl mq450:leading-[43px]">
              Closest to You
            </h1>
            <div className="w-[95%] min-w-[75vw] -mb-16 rounded-lg md:w-3/4 xl:w-1/2 h-auto mb-8 rounded-lg">
              <Map 
                setHeight="30vw" 
                setZoom={11} 
                offers={closestToYouOffers} 
                centerLat={userLatitude}
                centerLng={userLongitude}
                />
            </div>
            {isLoadingClosestToYou ? (
              <div className="h-[200px] text-center flex text-11xl items-center justify-center">Loading closest offers...</div>
            ) : errorClosestToYou ? (
              <div>{errorClosestToYou}</div>
            ) : (
              <Caroussel
                activities={
                  closestToYouOffers.map((offer) => (
                    <Rounder
                      key={`nearby-${offer.id}`}
                      id={offer.id}
                      images={offer.images}
                      name={offer.name}
                      categories={offer.categories}
                      price={offer.price}
                      longitude={offer.longitude}
                      latitude={offer.latitude}
                      promotionType={offer.promotionType}
                      percentageDiscount={offer.percentageDiscount}
                      buy_x={offer.minimumPurchaseAmount}
                      get_y={offer.discountValue}
                    />
                  ))
                }
              />
            )}
          </div>
        </section>
      <section className="self-stretch bg-zinc-100 flex flex-row items-center justify-center relative max-w-full text-center text-29xl text-main-teal font-subhead">
        <div className="flex-1 flex flex-col items-center justify-start pt-0 px-0 pb-[67px] box-border gap-[26px] max-w-full text-left text-mini-2 mq450:pb-[29px] mq450:box-border mq1050:pb-11 mq1050:box-border">
            <div className="self-stretch flex flex-col items-center justify-start gap-[19px] max-w-full text-left text-base text-copy">
            <Caroussel
              title="Restaurants"
              activities={
                trendingOffers
                  .filter(offer => offer.categories.includes("Restaurant"))
                  .map((offer) => (
                    <Rounder
                      key={`eat-${offer.id}`}
                      id={offer.id}
                      images={offer.images}
                      name={offer.name}
                      categories={offer.categories}
                      price={offer.price}
                      longitude={offer.longitude}
                      latitude={offer.latitude}
                      promotionType={offer.promotionType}
                      percentageDiscount={offer.percentageDiscount}
                      buy_x={offer.minimumPurchaseAmount}
                      get_y={offer.discountValue}
                    />
                  ))
              }
            />
            </div>
        </div>
      </section>
      <section className="self-stretch pb-20 bg-white-100 flex flex-row items-start justify-start relative max-w-full text-center text-29xl text-main-teal font-subhead">
        <div className="flex-1 flex flex-col items-end justify-start pt-0 px-0 pb-[67px] box-border gap-[26px] max-w-full text-left text-mini-2 mq450:pb-[29px] mq450:box-border mq1050:pb-11 mq1050:box-border">
          <div className="self-stretch flex flex-col items-center justify-start gap-[19px] max-w-full text-left text-base text-copy">
          <Caroussel
              title="Events "
              activities={
                trendingOffers
                  .filter(offer => offer.categories.includes("Nightlife"))
                  .map((offer) => (
                    <Rounder
                      key={`Events-${offer.id}`}
                      id={offer.id}
                      images={offer.images}
                      name={offer.name}
                      categories={offer.categories}
                      price={offer.price}
                      longitude={offer.longitude}
                      latitude={offer.latitude}
                      promotionType={offer.promotionType}
                      percentageDiscount={offer.percentageDiscount}
                      buy_x={offer.minimumPurchaseAmount}
                      get_y={offer.discountValue}
                    />
                  ))
              }
            />
          </div>
        </div>
      </section>
      <div className="flex flex-col"> 
        <footer className="mt-auto"> {/* This will push the footer to the bottom */}
          <UserFooter
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
          />
        </footer>
      </div>
    </div>)        

    :          

    (
    //Mobile JSX  
    <div className="mq1050:hidden w-full relative bg-white overflow-hidden flex flex-col justify-start px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead pt-[73px] mq780:pt-0">
      <Header />
      <div className="pt-0 bg-white"><QuizBlock /> </div>
      <div className="hidden gap-3 px-3 text-[12px] text-subhead flex flex-row justify-between">
        <div className="text-blue-900 cursor-pointer w-20 flex flex-col relative">
          <h3>Explore</h3>
          <div className="h-[1px] w-full bg-blue-900 z-10" />
        </div>
        <div className="cursor-pointer w-20">
          <h3>Map</h3>
        </div>
        <div className="cursor-pointer w-20">
          <h3>Likes</h3>
        </div>
        <div className="cursor-pointer w-20">
          <h3>Itineraries</h3>
        </div>
      </div>
      <div className="mt-[-5px] w-full border border-solid bg-neutral-300 border-neutral-300 min-h-[0.1px] max-md:max-w-full" />
      {localStorage.getItem("jwtToken")&& localStorage.getItem("userType") == "CUSTOMER" && <section className="pb-30 self-stretch bg-white-100 flex flex-col items-start justify-start  relative max-w-full text-center text-29xl text-main-teal font-subhead">
        <div className="flex flex-row justify-between w-full">
        <p className="pl-6 font-bold font-subhead m-0 py-4 text-10xl">Deals for You</p>
        <button onClick={onFilterClick} className="cursor-pointer [border:none] p-0 bg-[transparent] flex items-center justify-center pr-4">
          <div className="rounded-lg bg-white flex items-center justify-center py-[7px] px-[10px] border-[1px] border-solid border-steelblue">
            <div className="flex-1 relative text-base tracking-[-0.01em] leading-[15px] font-medium text-main-teal text-center">
              View All
            </div>
          </div>
        </button>
        </div>
        <div className="w-full px-6 pb-32 overflow-x-scroll overflow-y-hidden snap-x snap-mandatory scrollbar-hide">
          <div className="flex flex-nowrap gap-4 pr-12 min-w-max">
            {dealsForYouOffers.map((offer) => (
              <MobileRounder
                key={`forYou-${offer.id}`}
                id={offer.id}
                images={offer.images}
                name={offer.name}
                categories={offer.categories}
                price={offer.price}
                longitude={offer.longitude}
                latitude={offer.latitude}
                promotionType={offer.promotionType}
                percentageDiscount={offer.percentageDiscount}
                buy_x={offer.minimumPurchaseAmount}
                get_y={offer.discountValue}
              />
            ))}
          </div>
        </div>
      </section>}
      <section className="pb-30 self-stretch bg-zinc-100 flex flex-col items-start justify-start  relative max-w-full text-center text-29xl text-main-teal font-subhead">
        <p className="pl-6 font-bold font-subhead m-0 py-4 text-10xl">Trending</p>
        <div className="w-full px-6 pb-32 overflow-x-scroll overflow-y-hidden snap-x snap-mandatory scrollbar-hide">
          <div className="flex flex-nowrap gap-4 pr-12 min-w-max">
          {trendingOffers.map((offer) => (
              <MobileRounder
                key={`trending-${offer.id}`}
                id={offer.id}
                images={offer.images}
                name={offer.name}
                categories={offer.categories}
                price={offer.price}
                longitude={offer.longitude}
                latitude={offer.latitude}
                promotionType={offer.promotionType}
                percentageDiscount={offer.percentageDiscount}
                buy_x={offer.minimumPurchaseAmount}
                get_y={offer.discountValue}
              />
            ))}
          </div>
        </div>
      </section>
      {/* Mobile Businesses Section */}
      <section className="pb-30 self-stretch bg-white-100 flex flex-col items-start justify-start relative max-w-full text-center text-29xl text-main-teal font-subhead">
        <p className="pl-6 font-bold font-subhead m-0 py-4 text-10xl">Businesses</p>
        <div className="w-full px-6 pb-10 overflow-x-scroll snap-x snap-mandatory scrollbar-hide">
          <div className="flex flex-nowrap gap-4 pr-12 min-w-max">
            {businesses
              .filter(business => !EXCLUDED_BUSINESS_NAMES.includes(business.businessName))
              .map((business) => (
                <BusinessRounderMobile
                  key={`business-mobile-${business.id}`}
                  id={business.id}
                  businessName={business.businessName}
                  categories={business.categories}
                  profilePictureReference={business.profilePictureReference}
                />
              ))}
          </div>
        </div>
      </section>
      <section className="pb-0 mb-4 self-stretch bg-white flex flex-col items-start justify-start  relative max-w-full text-center text-29xl text-main-teal font-subhead">
        <p className="pl-6 font-bold font-subhead m-0 pt-4 text-10xl">Close to You</p>
      </section>
      <div className="mx-5 rounded-full w-auto bg-white -mt-3 mb-2"><Map offers={closestToYouOffers} setZoom={10} setHeight="250px"/></div>
      <section>       
      <div className="w-full px-6 pb-32 overflow-x-scroll overflow-y-hidden snap-x snap-mandatory scrollbar-hide">
        <div className="flex flex-nowrap gap-4 pr-12 min-w-max">
          {closestToYouOffers.map((offer) => (
            <MobileRounder
              key={`nearby-${offer.id}`}
              id={offer.id}
              images={offer.images}
              name={offer.name}
              categories={offer.categories}
              price={offer.price}
              longitude={offer.longitude}
              latitude={offer.latitude}
              promotionType={offer.promotionType}
              percentageDiscount={offer.percentageDiscount}
              buy_x={offer.minimumPurchaseAmount}
              get_y={offer.discountValue}
            />
          ))}
        </div>
      </div>
      </section>
      <section className="pb-30 self-stretch bg-zinc-100 flex flex-col items-start justify-start  relative max-w-full text-center text-29xl text-main-teal font-subhead">
        <p className="pl-6 font-bold font-subhead m-0 py-4 text-10xl">Events</p>
        <div className="w-full px-6 pb-32 overflow-x-scroll overflow-y-hidden snap-x snap-mandatory scrollbar-hide">
          <div className="flex flex-nowrap gap-4 pr-12 min-w-max">
            {trendingOffers
              .filter(offer => offer.categories.includes("Nightlife"))
              .map((offer) => (
                <MobileRounder
                  key={`Events-${offer.id}`}
                  id={offer.id}
                  images={offer.images}
                  name={offer.name}
                  categories={offer.categories}
                  price={offer.price}
                  longitude={offer.longitude}
                  latitude={offer.latitude}
                  promotionType={offer.promotionType}
                  percentageDiscount={offer.percentageDiscount}
                  buy_x={offer.minimumPurchaseAmount}
                  get_y={offer.discountValue}
                />
              ))
            }
          </div>
      </div>
      </section>
      <section className="pb-30 self-stretch bg-white flex flex-col items-start justify-start  relative max-w-full text-center text-29xl text-main-teal font-subhead">
        <p className="pl-6 font-bold font-subhead m-0 py-4 text-10xl">Restaurants</p>
        <div className="w-full px-6 pb-32 overflow-x-scroll overflow-y-hidden snap-x snap-mandatory scrollbar-hide">
          <div className="flex flex-nowrap gap-4 pr-12 min-w-max">
          {trendingOffers
            .filter(offer => offer.categories.includes("Restaurant"))
            .map((offer) => (
              <MobileRounder
                key={`eat-${offer.id}`}
                id={offer.id}
                images={offer.images}
                name={offer.name}
                categories={offer.categories}
                price={offer.price}
                longitude={offer.longitude}
                latitude={offer.latitude}
                promotionType={offer.promotionType}
                percentageDiscount={offer.percentageDiscount}
                buy_x={offer.minimumPurchaseAmount}
                get_y={offer.discountValue}
              />
            ))
          }
        </div>
      </div>
      </section>
      <section className="pb-30 mt-16 self-stretch bg-white flex flex-col items-center justify-center relative max-w-full text-center text-29xl text-main-teal font-subhead">
      </section>
      <footer className="mt-auto">
        <UserFooter
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </footer>
    </div>
    )}
    </>
  );
};

export default HomeScreen;