import { FunctionComponent, useCallback, useEffect, useState, memo } from "react";
import { useNavigate } from 'react-router-dom';
import { useWindowWidth } from "../../hooks/useWindowWidth";

const QuizBlock: FunctionComponent = memo(() => {
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 1050;
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);

  useEffect(() => {
    const img = new Image();
    img.src = '/background@2x.png';
    img.onload = () => setBackgroundImage('/background@2x.png');
  }, []);

  const onNavigateToMap = useCallback(() => {
    navigate("/map");
  }, [navigate]);

  const commonClasses = "px-[5%] flex flex-col items-start justify-start py-[20px] mq1050:py-[30px] relative gap-[30px] text-left text-white font-subhead";
  const desktopClasses = `hidden mq1050:flex ${commonClasses} mq750:gap-[29px_58px] mq750:py-[65px] mq750:px-[25px] mq1225:px-[50px]`;
  const mobileClasses = `mq1050:hidden ${commonClasses} py-[20px]`;

  const backgroundStyle = backgroundImage
    ? {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
      }
    : {
        backgroundColor: 'rgb(228, 228, 231)', // zinc-100 equivalent
      };

  const DesktopContent = (
    <div className="flex flex-col items-start justify-start gap-[31px] max-w-full mq750:gap-[15px_31px] py-4">
      <h1 className="m-0 w-full relative text-41xl leading-[80px] font-bold tracking-tight mq1050:text-[60px] mq1050:leading-[60px] mq750:text-[50px] mq750:leading-[50px] mq450:text-[40px] mq450:leading-[40px]">
        Let Us Help You!
      </h1>
      <div className="flex flex-row gap-3">
        <h2 className="m-0 w-[70%] relative text-11xl leading-[50px] font-semibold tracking-[-0.01em] mq1050:text-[30px] mq1050:leading-[40px] mq750:text-[25px] mq750:leading-[35px] mq450:text-[20px] mq450:leading-[30px]">
          Find exclusive offers<br/>nearby
        </h2>
        <button
          className="flex cursor-pointer border-none py-[15px] ml-[5vw] mt-6 pr-[73px] pl-[74px] bg-coral rounded-md items-center justify-center whitespace-nowrap z-2 hover:bg-chocolate mq450:pl-5 mq450:pr-5"
          onClick={onNavigateToMap}
        >
          <b className="relative py-2 text-5xl font-bold text-white mq450:text-[20px]">
            Let's Go!
          </b>
        </button>
      </div>
    </div>
  );

  const MobileContent = (
    <>
      <div className="flex flex-col items-start justify-start max-w-full">
        <h1 className="m-0 pt-0 w-full relative text-[36px] leading-[80px] font-bold tracking-tight mq1050:text-[60px] mq750:text-[50px] mq750:leading-[50px] mq450:text-[40px] mq450:leading-[40px]">
          Let Us Help You!
        </h1>
        <h2 className="m-0 whitespace-nowrap w-[90%] mq480:w-[60%] relative text-[20px] leading-[50px] font-semibold tracking-[-0.01em] mq1050:text-[30px] mq1050:leading-[40px] mq750:text-[25px] mq750:leading-[35px] mq450:text-[20px] mq450:leading-[30px] pt-0 -mt-4">
          Find exclusive offers nearby
        </h2>
      </div>
      <button
        className="cursor-pointer border-none py-5 px-5 bg-coral rounded-md flex items-center justify-center whitespace-nowrap z-2 hover:bg-chocolate mq450:pl-5 mq450:pr-5 mb-4"
        onClick={onNavigateToMap}
      >
        <b className="relative text-[22px] font-bold text-white mq450:text-[20px]">
          Let's Go!
        </b>
      </button>
    </>
  );

  return (
    <section className={isMobile ? mobileClasses : desktopClasses} style={backgroundStyle}>
      {isMobile ? MobileContent : DesktopContent}
    </section>
  );
});

QuizBlock.displayName = 'QuizBlock';

export default QuizBlock;