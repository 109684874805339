import * as React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import { useEffect, useState, useCallback, useRef, MutableRefObject } from "react";

type SideNavPostProps = {
  selected: "Post pricing details" | "Upload photos" | "Review my post";
};

const SideNavPost: React.FC<SideNavPostProps> = ({ selected }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { offerId } = useParams<{ offerId?: string }>();
  const prevLocationRef: MutableRefObject<string | undefined> = useRef<string | undefined>(undefined);

  const [postInfo, setPostInfo] = useState({
    name: "Name of the post",
    startDate: "MM/DD/YYYY",
    endDate: "MM/DD/YYYY",
    location: "XXXX"
  });
  const [lastPage, setLastPage] = useState("My Deals");

  const formatDate = (dateString: string): string => {
    if (!dateString) return "MM/DD/YYYY";
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
  };

  useEffect(() => {
    prevLocationRef.current = location.pathname;
  }, [location]);

  useEffect(() => {
    const savedOfferData = localStorage.getItem('offerData');
    if (savedOfferData) {
      const parsedOfferData = JSON.parse(savedOfferData);
      setPostInfo({
        name: parsedOfferData.offer?.name || "Name of the post",
        startDate: formatDate(parsedOfferData.offer?.recurrencePattern?.startDate),
        endDate: formatDate(parsedOfferData.offer?.recurrencePattern?.endDate),
        location: parsedOfferData.offer?.locations?.[0]?.address || "XXXX"
      });
    }

    // Set last page based on current location
    switch (location.pathname) {
      case "/merchant-hub-post-1":
        setLastPage("My Deals");
        break;
      case "/merchant-hub-post-2":
        setLastPage("Business Details");
        break;
      case "/merchant-hub-post-3":
        setLastPage("Upload Photos");
        break;
      default:
        setLastPage("Previous page");
    }
  }, [location.pathname]);

  const removeCachedData = () => {
    localStorage.removeItem('offerData');
    localStorage.removeItem('uploadedImages');
    localStorage.removeItem('promotionData');
  }

  useEffect(() => {
    if (!offerId && prevLocationRef.current === '/merchant-hub-deals') {
      removeCachedData();
    }
  }, [offerId]);

  const onGoBackClick = useCallback(() => {
    if(offerId) 
      removeCachedData();

    switch (location.pathname) {
      case "/merchant-hub-post-1":
        navigate("/merchant-hub-deals");
        break;
      case "/merchant-hub-post-2":
        navigate("/merchant-hub-post-1");
        break;
      case "/merchant-hub-post-3":
        navigate("/merchant-hub-post-2");
        break;
      default:
        navigate(-1);
    }
  }, [navigate, location.pathname]);

  return (
    <div className="min-h-full hidden mq780:flex flex flex-col justify-start h-auto mx-0  w-[20vw] text-base font-medium leading-7 min-w-[205px] max-w-[480px] text-zinc-800 bg-zinc-100">
      <div className="flex flex-col pt-3 pb-3 w-full font-subhead">
        <div className="flex flex-row justify-start pl-11">
            <ChevronLeft onClick={onGoBackClick} className="cursor-pointer w-6 h-6 pt-2.5 px-3 stroke-black" />
            <p onClick={onGoBackClick} className="bg-transparent mt-2 font-semibold">{lastPage}</p>
        </div>
        <div className="h-[0.1px] w-inherit ml-14 bg-zinc-400" />
      </div>
      <div className="flex flex-col pl-14">
        <p className="font-semibold m-2">{postInfo.name}</p>
        <p className="text-mini m-2">Start Date: <br/>{postInfo.startDate}</p>
        <p className="text-mini m-2">End Date: <br/>{postInfo.endDate}</p>
        <p className="text-mini m-2">Location: <br/>{postInfo.location}</p>
        <p className="hidden text-mini [text-decoration:underline] font-semibold text-blue-500 m-2">Preview post</p>
      </div>
    </div>
  );
};

export default SideNavPost;