import * as React from "react";
import { useNavigate } from 'react-router-dom';
import { AlignJustify } from "lucide-react";

interface MerchantLandingPageProps {
  onSignUp?: () => void;
}

function MerchantLandingPage({ onSignUp }: MerchantLandingPageProps) {

    const navigate = useNavigate();

    const onSignupClick = () => {
      if (localStorage.getItem("jwtToken") && localStorage.getItem("userType") == "MERCHANT") {
        navigate('/merchant-hub-home'); 
      } else {
        navigate('/merchant-signup'); 
      }
    };

  return (
    <div className="flex flex-col items-center mb-40">
      <div className="flex overflow-hidden relative flex-col justify-center self-stretch w-full font-bold text-center max-h-[65vh] 580px:max-h-[60vh] max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/3093b24348788dc76aa5def969bb2a788152adb64aa5420aacd19568888a1c3b?apiKey=0d607e805d95499aa95fa0a93ed5f083&"
          className="object-cover absolute inset-0 size-full"
        />
        <div className="flex overflow-hidden relative flex-col justify-center w-full min-h-[818px] max-md:max-w-full">
        <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d3402ec67a0842fb8172eacbb33d837d771bd0c2330a9be21e23e63ee0fa4503?apiKey=0d607e805d95499aa95fa0a93ed5f083&"
            className="object-cover absolute inset-0 size-full "
          />
          <div className="flex relative justify-center items-center py-10 w-full bg-blend-multiply bg-black bg-opacity-25 max-md:px-5 max-md:max-w-full mq580:items-start mq580:justify-start">
            <div className="flex flex-col mt-40 mb-36 max-w-full w-[835px] max-md:my-10 mq480:mt-50 items-start 580px:items-center justify-center 580px:justify-center">
              <div className="mq780:text-61xl text-41xl px-5 leading-[65px] text-white mq480:leading-[80px] max-md:max-w-full text-center mq480:text-center mq480:max-w-full">
                Go big, while staying local.
              </div>
                <div className="flex flex-row w-full items-center justify-center gap-2">
                  <button
                    className="hidden mb-0 cursor-pointer font-bold justify-center self-center px-10 py-4 mb-5 mt-10 text-xl tracking-tight text-white bg-blue-900 rounded-md max-md:px-5 max-md:mt-10"
                    onClick={() => navigate('/merchant-login')}
                  >
                    Log in
                  </button>
                  <button
                    className="mb-0 cursor-pointer font-bold justify-center self-center px-10 py-4 mb-5 mt-10 text-xl tracking-tight text-white bg-blue-900 rounded-md max-md:px-5 max-md:mt-10"
                    onClick={onSignupClick}
                  >
                    Sign up
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden mt-28 text-19xl mx-10 font-semibold tracking-tight text-center leading-[50.04px] text-zinc-800 max-md:mt-10 max-md:max-w-full">
        How we improve business
      </div>
      <div style={{ minWidth: 'max(250px, 40vw)' }} className="hidden mt-4 mb-8 px-72 text-base leading-7 text-center text-zinc-800 max-md:max-w-full">
      DoYouWeekend is a platform that helps small businesses offer promotions and connect with new customers without the 
      aggressive fees that Groupon charges. Just as social media matches you with content you like, we use AI to match
       consumers with experiences and businesses they will love.
      </div>
      <div className="mt-28 px-5 text-19xl font-semibold tracking-tight text-center leading-[50.04px] text-zinc-800 max-md:mt-10">
        How we improve Business
      </div>
      <div className="flex gap-1.5 mt-16 w-full max-w-[1303px] max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
        <div className="flex-auto max-md:max-w-full">
          <div className="flex px-5 gap-8 max-md:gap-0 580px:gap-5 580px:flex-col box-border">
            <div className="flex flex-col w-[33%] 580px:w-full max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow items-center pb-7 text-center text-zinc-800 max-md:mt-8">
                <img
                  loading="lazy"
                  src="advantage-1.png"
                  className="max-w-full aspect-square w-[277px]"
                />
                <div className="mt-16 580px:text-xl font-semibold tracking-tight leading-12 mq480:text-xl 580px:leading-2 580px:mt-8">
                  Low fees and fair pricing
                </div>
                <div className="pb-6 self-stretch mt-7 leading-7 text-base">
                  Unlike competitors, doyouweekend offers low, transparent fees, helping small business owners keep more of their revenue from promotions.{" "}
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[33%] 580px:w-full max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow items-center pb-7 text-center text-zinc-800 max-md:mt-8">
                <img
                  loading="lazy"
                  src="advantage-3.png"
                  className="max-w-full aspect-square w-[277px]"
                />
                <div className="mt-16 580px:text-xl font-semibold tracking-tight leading-12 mq480:text-xl 580px:leading-2 580px:mt-8">
                  Targeted Customer Reach
                </div>
                <div className="pb-6 self-stretch mt-7 leading-7 text-base">
                  Our advanced algorithms match promotions with genuinely interested users, boosting conversions and attracting repeat customers. {" "}
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[33%] 580px:w-full max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow items-center pb-7 text-center text-zinc-800 max-md:mt-8">
                <img
                  loading="lazy"
                  src="advantage-2.png"
                  className="max-w-full aspect-square w-[277px]"
                />
                <div className="mt-16 580px:text-xl font-semibold tracking-tight leading-12 mq480:text-xl mq780:mb-0 580px:leading-2 580px:mt-8">
                  Loyal customer base
                </div>
                <div className="self-stretch mt-7 leading-7 text-base">
                  We build your loyal customer base by connecting you with interested users. Deals and personalized suggestions encourage repeat visits.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantLandingPage;