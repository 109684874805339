import React, { useState, useRef, useEffect } from "react";
import { Filter } from "lucide-react";
import { MapPin, CalendarIcon, ChevronDown, ChevronUp } from "lucide-react";
import CalendarDateRange from "../General/CalendarDateRange";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface SideNavProps {
  updateSearchParams: (params: Partial<SearchParams>) => void;
}

const locations = [
  // "Orlando, FL",
  "Fort Myers, FL"
];

interface Category {
  id: string;
  name: string;
}

interface Highlight {
  id: string;
  name: string;
}


interface SearchParams {
  query?: string;
  categories?: string[];
  highlights?: string[];
  priceRange?: string;
  dealType?: string;
  startDate?: string;
  endDate?: string;
  latitude?: number;
  longitude?: number;
  page?: number;
  size?: number;
  location?: string;
}

const categories = [
  "Food & Drinks",
  "Nature",
  "Nightlife",
  "Shopping & Fashion",
  "Classes & Workshops",
  "Museums, History & Culture",
  "Water Activities",
  "Sports",
  "Tours & Excursions",
  "Entertainment & Leisure",
];

const priceRanges = ["Free", "$", "$$", "$$$"];

const accommodations = [
  "Wheel Chair Accessibility",
  "Pets allowed",
  "Children allowed",
  "Free WiFi",
  "Assistive Devices",
  "Paratransit Service",
];

const DealsAndCoupons = [
  "Only Deals",
];

const searchOffers = async (params: SearchParams) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/v1/recommendations/search`, {
      params: {
        query: params.query,
        categories: params.categories?.join(','),
        highlights: params.highlights?.join(','),
        priceRange: params.priceRange,
        dealType: params.dealType,
        startDate: params.startDate,
        endDate: params.endDate,
        latitude: params.latitude,
        longitude: params.longitude,
        page: params.page || 0,
        size: params.size || 20,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error searching offers:', error);
    throw error;
  }
};

const SideNav: React.FC<SideNavProps> = ({ updateSearchParams }) => {
  const navigate = useNavigate();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState<string | null>(null);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [selectedPrices, setSelectedPrices] = useState<string[]>([]);
  const [selectedAccommodations, setSelectedAccommodations] = useState<string[]>([]);
  const [selectedDeals, setSelectedDeals] = useState<string[]>([]);
  const [calendarFromShown, setCalendarFromShown] = useState(false);
  const [calendarToShown, setCalendarToShown] = useState(false);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [fromMonth, setFromMonth] = useState(new Date().getMonth());
  const [toMonth, setToMonth] = useState((new Date().getMonth() + 1) % 12);
  const [year, setYear] = useState(new Date().getFullYear());
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [selectedLocation, setSelectedLocation] = useState(localStorage.getItem("userLocation") || "Fort Myers, FL");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [highlights, setHighlights] = useState<Highlight[]>([]);
  const [selectedHighlights, setSelectedHighlights] = useState<string[]>([]);
  const [showAllHighlights, setShowAllHighlights] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get<Category[]>(`${API_BASE_URL}/v1/catalog/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };

    const fetchHighlights = async () => {
      try {
        const response = await axios.get<Highlight[]>(`${API_BASE_URL}/v1/catalog/highlights`);
        setHighlights(response.data);
      } catch (error) {
        console.error('Failed to fetch highlights:', error);
      }
    };

    fetchCategories();
    fetchHighlights();
  }, []);

  const toggleCategory = (categoryId: string) => {
    setSelectedCategories(prevSelected => {
      const newSelected = prevSelected.includes(categoryId)
        ? prevSelected.filter(id => id !== categoryId)
        : [...prevSelected, categoryId];
      
      // Update this line
      updateSearchParams({ categories: newSelected });
      
      return newSelected;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleHighlight = (highlightId: string) => {
    setSelectedHighlights(prevSelected => {
      const newSelected = prevSelected.includes(highlightId)
        ? prevSelected.filter(id => id !== highlightId)
        : [...prevSelected, highlightId];
      
      updateSearchParams({ highlights: newSelected });
      
      return newSelected;
    });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectLocation = (location: string) => {
    setSelectedLocation(location);
    localStorage.setItem('userLocation', location);
    
    const coordinates = {
      "Fort Myers, FL": { lat: 26.6406, lng: -81.8723 },
      // "Orlando, FL": { lat: 28.5383, lng: -81.3792 }
    };
    
    const selectedCoords = coordinates[location as keyof typeof coordinates];
    localStorage.setItem('userLatitude', selectedCoords.lat.toString());
    localStorage.setItem('userLongitude', selectedCoords.lng.toString());
    
    setIsDropdownOpen(false);
    
    // Dispatch a custom event
    window.dispatchEvent(new Event('locationChange'));
    
    updateSearchParams({
      location: location,
      latitude: selectedCoords.lat,
      longitude: selectedCoords.lng
    });
  };

  const handleDateSelect = (date: Date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
    } else if (date > startDate) {
      setEndDate(date);
      updateSearchParams({
        startDate: startDate.toISOString(),
        endDate: date.toISOString()
      });
      setIsCalendarOpen(false);
    } else {
      setStartDate(date);
      setEndDate(null);
    }
  };

  const handleMonthChange = (offset: number) => {
    const newDate = new Date(currentYear, currentMonth + offset);
    setCurrentYear(newDate.getFullYear());
    setCurrentMonth(newDate.getMonth());
  };

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const formatDateRange = () => {
    if (startDate && endDate) {
      return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
    } else if (startDate) {
      return startDate.toLocaleDateString();
    }
    return "Select date range";
  };

  const toggleSelection = (item: string, state: string[], setState: React.Dispatch<React.SetStateAction<string[]>>) => {
    setState(prevState =>
      prevState.includes(item)
        ? prevState.filter(i => i !== item)
        : [...prevState, item]
    );
  };

  const handleFromMonthChange = (offset: number) => {
    const newMonth = (fromMonth + offset + 12) % 12;
    setFromMonth(newMonth);
    if (offset === 1 && newMonth === 0) {
      setYear(year + 1);
    } else if (offset === -1 && newMonth === 11) {
      setYear(year - 1);
    }
  };

  const handleToMonthChange = (offset: number) => {
    setToMonth((prevMonth) => (prevMonth + offset + 12) % 12);
  };

  const handleCalendarFromClick = () => {
    setCalendarFromShown(!calendarFromShown);
  }

  const handleCalendarToClick = () => {
    setCalendarToShown(!calendarToShown);
  }

  const handleFromDateSelect = (date: Date) => {
    setFromDate(date);
    handleCalendarFromClick();
    if (toDate && date > toDate) {
      setToDate(null);
    }
  };

  const handlePriceRangeChange = (range: string) => {
    if (selectedPriceRange === range) {
      setSelectedPriceRange(null);
      updateSearchParams({ priceRange: undefined });
    } else {
      setSelectedPriceRange(range);
      updateSearchParams({ priceRange: range });
    }
  };

  const handleCustomPriceRangeSubmit = () => {
    if (minPrice || maxPrice) {
      const priceRange = `${minPrice || ''}-${maxPrice || ''}`;
      updateSearchParams({ priceRange });
      setSelectedPriceRange(null);
    }
  };

  const clearAllFilters = () => {
    setSelectedCategories([]);
    setSelectedPrices([]);
    setSelectedAccommodations([]);
    setSelectedHighlights([]);
    updateSearchParams({
      categories: undefined,
      priceRange: undefined,
      dealType: undefined,
      highlights: undefined,
      startDate: undefined,
      endDate: undefined,
    });
    navigate("/map");
  };

  return (
    <div className="w-full md:w-[300px] lg:w-[30vw] max-w-[350px] text-base font-medium pl-8 md:pl-10 bg-whitesmoke flex flex-col items-start justify-start pt-6 md:pt-[88px] pb-8 md:pb-[1090px] px-4 md:px-auto box-border gap-[14px] z-[2] text-left text-11xl text-main-teal font-subhead">
      <div ref={dropdownRef} className="relative w-[280px]">
        <div
          onClick={toggleDropdown}
          className="flex items-center bg-whitesmoke rounded-md p-2 border border-solid border-blue-900 mt-10 cursor-pointer"
        >
          <MapPin className="text-main-teal mr-2" size={30} />
          <input
            type="text"
            readOnly
            value={selectedLocation}
            className="bg-transparent text-zinc-900 w-full text-lg border-none outline-none text-copy cursor-pointer"
          />
          <ChevronDown className="text-main-teal ml-2" size={20} />
        </div>
        {isDropdownOpen && (
          <div className="absolute w-full mt-1 bg-white border border-solid border-blue-900 rounded-md shadow-lg z-10">
            {locations.map((location, index) => (
              <div
                key={index}
                onClick={() => selectLocation(location)}
                className="p-2 hover:bg-blue-100 bg-zinc-50 cursor-pointer text-zinc-900 text-lg rounded-md"
              >
                {location}
              </div>
            ))}
          </div>
        )}
        </div>
        <div
          onClick={toggleCalendar}
          className="flex items-center bg-whitesmoke rounded-md p-2 border border-solid border-blue-900 cursor-pointer w-[262px]"
        >
          <CalendarIcon className="text-main-teal mr-2" size={30} />
          <input
            type="text"
            readOnly
            value={formatDateRange()}
            className="bg-transparent w-full text-zinc-900 text-lg border-none outline-none text-copy mt-[2px]"
          />
          <ChevronDown className="text-main-teal ml-2" size={20} />
        </div>
        {isCalendarOpen && (
          <div className="absolute z-10 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg mt-40">
            <CalendarDateRange
              startDate={startDate}
              endDate={endDate}
              onDateSelect={handleDateSelect}
              year={currentYear}
              month={currentMonth}
              onMonthChange={handleMonthChange}
            />
          </div>
        )}

        <h3 className="text-5xl font-semibold mt-8 mb-0">Categories</h3>
        <div className="self-stretch flex flex-col gap-2 text-lg md:text-xl text-copy">
        {categories.map((category) => (
          <div
            key={category.id}
            className={`cursor-pointer ${
              selectedCategories.includes(category.id) ? "text-blue-800 font-semibold" : ""
            }`}
            onClick={() => toggleCategory(category.id)}
          >
            {category.name}
          </div>
        ))}
      </div>

      <h3 className="text-5xl font-semibold mt-8 mb-0">Price Range</h3>
      <div className="self-stretch flex flex-col gap-2 text-lg text-copy">
        {[
          { label: "Free", value: "FREE" },
          { label: "$", value: "$" },
          { label: "$$", value: "$$" },
          { label: "$$$", value: "$$$" },
        ].map(({ label, value }) => (
          <label key={value} className="flex items-center">
            <input
              type="checkbox"
              className="mr-2 w-4 h-4 cursor-pointer"
              checked={selectedPriceRange === value}
              onChange={() => handlePriceRangeChange(value)}
            />
            {label}
          </label>
        ))}
      </div>
      <div className="flex w-full flex-row justify-between max-w-[230px]">
        <input
          type="text"
          placeholder="$ Min"
          className="font-subhead font-semibold text-[#478abf] w-14 p-2 border border-gray-300 rounded"
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
        />
        <input
          type="text"
          placeholder="$ Max"
          className="font-subhead font-semibold text-[#478abf] w-14 p-2 border border-gray-300 rounded"
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
        />
        <button 
          className="cursor-pointer bg-blue-900 font-semibold text-white px-4 mr-4 py-1.5 rounded-lg"
          onClick={handleCustomPriceRangeSubmit}
        >
          Go
        </button>
      </div>

      <h3 className="text-5xl font-semibold mt-8 mb-0">Deals & Coupons</h3>
      <div className="self-stretch flex flex-col gap-2 text-lg text-copy">
        {DealsAndCoupons.map((item) => (
          <label key={item} className="flex items-center">
            <input
              type="checkbox"
              className="mr-2 w-4 h-4 cursor-pointer"
              onChange={() => toggleSelection(item, selectedDeals, setSelectedDeals)}
            />
            {item}
          </label>
        ))}
      </div>

      <h3 className="text-5xl font-semibold mt-8 mb-0">Highlights</h3>
      <div className="self-stretch flex flex-col gap-2 text-lg text-copy">
        {highlights.slice(0, showAllHighlights ? highlights.length : 5).map((highlight) => (
          <label key={highlight.id} className="flex items-center">
            <input
              type="checkbox"
              className="mr-2 w-4 h-4 cursor-pointer"
              checked={selectedHighlights.includes(highlight.id)}
              onChange={() => toggleHighlight(highlight.id)}
            />
            {highlight.name}
          </label>
        ))}
        {highlights.length > 5 && (
          <button
            className="text-lg bg-whitesmoke -mr-1 text-blue-500 cursor-pointer"
            onClick={() => setShowAllHighlights(!showAllHighlights)}
          >
            {showAllHighlights ? (
              <div className="flex flex-row gap-2 pt-3 -ml-1">
                <p className="m-0 mt-[2px]">Show less</p> <ChevronUp className="inline" size={24} />
              </div>
            ) : (
              <div className="flex flex-row gap-2 -ml-1">
                <p className="m-0 mt-[2px]">Show more</p> <ChevronDown className="inline" size={24} />
              </div>
            )}
          </button>
        )}
      </div>
      <div className="w-[75%] flex flex-row items-center justify-center gap-3">
        <button
          className="cursor-pointer whitespace-nowrap mt-10 pb-2 pt-2.5 px-10 bg-white text-steelblue border border-steelblue rounded-lg font-bold hover:bg-steelblue hover:text-white transition-colors"
          onClick={clearAllFilters}
        >
          Clear All Filters
        </button>
        <button
          className="hidden cursor-pointer mt-10 flex flex-row pb-2 pt-2.5 px-9 bg-blue-900 text-zinc-100 border border-blue-800 bg-blue-800 rounded-lg font-bold hover:border-white transition-colors"
          onClick={clearAllFilters}
        >
          <Filter className="stroke-zinc-100 h-4 w-4 pr-2" />
          Filter
        </button>
      </div>   
    </div>
  );
};

export default SideNav;