import React from 'react';
import { X } from 'lucide-react';

type FullScreenImagePreviewProps = {
  imageUrl: string;
  alt?: string;
  onClose: () => void;
};

export const FullScreenImagePreview: React.FC<FullScreenImagePreviewProps> = ({ imageUrl, alt, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
      <div className="relative max-w-full max-h-full">
        <img src={imageUrl} alt={alt} className="max-w-full max-h-[90vh] object-contain" />
        <button
          onClick={onClose}
          className="absolute bg-transparent cursor-pointer top-2 right-2 text-white hover:text-gray-300 focus:outline-none"
          aria-label="Close preview"
        >
          <X size={28}  />
        </button>
      </div>
    </div>
  );
};