import * as React from "react";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { Download, AlignJustify, X } from "lucide-react";
import MobileDropdown from "../Mobile/MobileDropdown";
import DesktopDropdown from "./DesktopDropdown";
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface NavigationItemProps {
  text: string;
  onClick: () => void;
}

const NavigationItem: React.FC<NavigationItemProps> = ({ text, onClick }) => {
  return (
    <button
      className="mx-2 font-subhead text-base cursor-pointer bg-transparent tracking-tight text-light-grey hover:text-steelblue focus:text-blue-900"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

interface SearchBarProps {
  placeholder: string;
  iconSrc: string;
  id?: string;
  onClick: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ placeholder, iconSrc, id, onClick }) => {
  const [query, setQuery] = useState<string>("");
  const navigate = useNavigate();

  const handleSearch = async () => {
    if (query.trim()) {
      try {
        const response = await axios.get(`${API_BASE_URL}/v1/recommendations/search`, {
          params: {
            query: query,
            page: 0,
            size: 20,
          },
        });

        console.log('Search results:', response.data);

        // Navigate to the map page with the search query
        navigate(`/map?search=${encodeURIComponent(query)}&page=0&size=20`);
      } catch (error) {
        console.error('Error searching offers:', error);
        // You might want to show an error message to the user here
      }
    }
  };

  function handleInputChange(text: string) {
    setQuery(text);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  }

  return (
    <div className="flex-grow flex items-center mr-2 px-2 mt-1 bg-white rounded-lg border border-blue-900 border-solid">
      <img
        loading="lazy"
        src={iconSrc}
        alt="Search icon"
        className="shrink-0 w-6 h-6 mt-0 [border:none] [outline:none] cursor-pointer"
        onClick={handleSearch}
      />
      <input
        type="text"
        placeholder={placeholder}
        className="[border:none] [outline:none] pt-1 flex-grow pl-2 text-base leading-7 text-blue-900 bg-transparent outline-none"
        id={id}
        name="search"
        value={query}
        onChange={(e) => handleInputChange(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

interface ButtonProps {
  text: string;
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({ text, onClick }) => {
  return (
    <button
      className="hidden pb-0 my-1 mq1050:flex cursor-pointer flex-col justify-center rounded-lg border bg-coral border-blue-900 border-solid hover:border-steelblue py-0"
      onClick={onClick}
    >
      <div className="justify-center p-2 bg-coral rounded-lg text-base text-white font-bold">
        {text}
      </div>
    </button>
  );
};

function Header() {
  const navigate = useNavigate();
  const [isMobileDropdownVisible, setisMobileDropdownVisible] = useState(false);
  const [isDesktopDropdownVisible, setisDesktopDropdownVisible] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(localStorage.getItem("userLocation") || "Fort Myers, FL");
  
  const merchantnavigationClick = () => {
    const jwtToken = localStorage.getItem("jwtToken");
    const userType = localStorage.getItem("userType");
    
    if (jwtToken && userType === "MERCHANT") {
      navigate("/merchant-hub-home");
    } else {
      navigate("/merchant-hub");
    }
  };

  const navigationItems = [
    { text: "For Business", onClick: merchantnavigationClick },
    { text: "Map", onClick: () => navigate("/map") },
    // { text: "Likes", onClick: () => navigate('/likes') }, //LIKES
    { text: "My Itineraries", onClick: () => navigate("/itineraries") }
  ];

  const navigationItemsDropdown = [
    { text: "Activity Map", onClick: () => navigate('/map') },
    // { text: "Likes", onClick: () => navigate('/likes') }, //LIKES
    { text: "My Itineraries", onClick: () => navigate('/itineraries') },
    { text: "Merchant Hub", onClick: merchantnavigationClick }
  ];

  useEffect(() => {
    const handleLocationChange = () => {
      setCurrentLocation(localStorage.getItem("userLocation") || "Fort Myers, FL");
    };
  
    // Listen for the custom event
    window.addEventListener('locationChange', handleLocationChange);
  
    // Also listen for storage events (in case the location is changed from another tab)
    window.addEventListener('storage', handleLocationChange);
  
    return () => {
      window.removeEventListener('locationChange', handleLocationChange);
      window.removeEventListener('storage', handleLocationChange);
    };
  }, []);

  const onHomeClick = () => navigate('/');
  const onHomeClickMobile = () => navigate('/');
  const handleMerchantClick = () => navigate('/merchant-hub');
  const onLocationClick = () => navigate('/map');
  const onProfileClick = () => localStorage.getItem("userType") == "MERCHANT" ? navigate('/merchant-hub-home') 
                                                                              : window.innerWidth < 780 ? navigate('/account') : navigate('/account/profile'); 
  const onMenuClick = () => setisMobileDropdownVisible(!isMobileDropdownVisible);
  const onDesktopMenuClick = () => setisDesktopDropdownVisible(!isDesktopDropdownVisible);

  return (
    <>
      {/* DESKTOP HEADER */}
      {isDesktopDropdownVisible && <div className="overfloy-y-auto right-5 items-end absolute mt-[83px]"><DesktopDropdown /></div>}
      <div className="relative hidden pt-1 mq780:flex flex-col self-stretch w-full text-base font-medium bg-white text-zinc-700">
        <div className="flex w-full">
          <div className="flex items-center mt-2 align-text-bottom">
            <img
              loading="lazy"
              src="/new-logo@2x.png"
              alt="Logo"
              className="self-start ml-5 mb-2 mt-1 aspect-[5] w-[210px] cursor-pointer"
              onClick={onHomeClick}
            />
            <div className="hidden mt-1 lg-1340:flex mx-5">
              {navigationItems.map((item, index) => (
                <NavigationItem key={index} text={item.text} onClick={item.onClick} />
              ))}
            </div>
          </div>
          <div className="pl-5 mt-2 pt-1.5 pr-10 flex-grow">
          <SearchBar 
            placeholder="What do you want to do?" 
            iconSrc="/icon--search.svg" 
            id="search-bar" 
            onClick={() => {}}  // This is no longer used, but kept for compatibility
          />
          </div>
          <div className="flex gap-8 mt-2 tracking-tight text-white pr-7">
            <div className="hidden mq850:flex relative flex items-center cursor-pointer" onClick={onLocationClick}>
              {/* <div className="relative h-[31.5px] w-[22.6px]">
                <img className="left-[0.5px] h-[30px] w-[20.3px] relative z-10" alt="Pin base" src="/vector.svg" />
                <img className="h-[5.3px] w-[22.6px] relative z-20 mb-[35px]" alt="Pin shadow" src="/vector-1.svg" />
              </div> */}
              <img className="h-9 w-[24px] pt-1" src="/header-marker.png" alt="Pin"/>
              <span className="ml-2 tracking-tight font-subhead text-gray hover:text-steelblue focus:text-blue-900">{currentLocation}</span>
            </div>
            <div className="hidden"><Button text="For Merchant" onClick={handleMerchantClick} /></div>
            <div className="gap-2 flex flex-row items-center">
              {localStorage.getItem('jwtToken') ?
              <>
                <AlignJustify className="cursor-pointer block w-8 h-8 stroke-zinc-700 pt-1.5" onClick={onDesktopMenuClick} />
                <img loading="lazy" src="/Avatar_Default.png" alt="User avatar" className="mt-1 mr-2 h-[30px] w-[30px] cursor-pointer" onClick={onProfileClick} />
              </>
              : 
              <div className="flex flex-row gap-2">
                <button
                className="font-subhead text-lg w-[66px] bg-white whitespace-nowrap pb-2 ml-3 mt-2 pt-1 mq780:flex cursor-pointer flex-col justify-center rounded-lg text-main-teal font-semibold hover:font-bold"
                onClick={() => navigate("/login")}
                >
                  Login
                </button>
                <button
                  className="whitespace-nowrap pb-2 ml-3 mt-1 mq780:flex font-semibold hover:font-bold cursor-pointer flex-col justify-center rounded-lg border bg-blue-900 border-blue-900 border-solid hover:border-steelblue"
                  onClick={() => navigate("/signup")}
                >
                  <div className="pb-0 justify-center p-2 bg-blue-900 rounded-lg text-base text-center text-white">
                    Sign Up
                  </div>
                </button>
              </div> 
            }
            </div>
          </div>
        </div>
        <div className="mt-3.5 w-full border border-solid bg-neutral-300 border-neutral-300 min-h-[0.1px] max-md:max-w-full" />
      </div>

      {/* MOBILE HEADER */}
      <div className="mq780:hidden fixed top-0 left-0 right-0 z-50 flex-wrap w-full text-base font-medium bg-white text-zinc-700 mb-[78px]">
        <div className="px-3 flex flex-wrap items-center justify-between">
          {isMobileDropdownVisible ? 
            <X className="cursor-pointer stroke-light block mq780:hidden w-7 h-7 stroke-zinc-700 pt-6 pl-3" onClick={onMenuClick} />
            :
            <AlignJustify className="cursor-pointer stroke-light block mq780:hidden w-7 h-7 stroke-zinc-700 pt-6 pl-3" onClick={onMenuClick} />
          }
          <img loading="lazy" src="/new-logo@2x.png" alt="Logo" className={`self-start aspect-[5] w-[180px] mt-3 cursor-pointer shrink-2 ${localStorage.getItem("jwtToken") ? "" : "ml-[12px]"}`} onClick={onHomeClickMobile} />
          <div className="hidden flex justify-between flex-row shrink-2 mq780:justify-start">
            <div className="hidden lg-1340:flex mx-5">
              {navigationItems.map((item, index) => (
                <NavigationItem key={index} text={item.text} onClick={item.onClick} />
              ))}
            </div>
          </div>
          <div className="flex flex-wrap justify-center sm:justify-between gap-8 mt-2 text-white">
            <div className="relative items-center cursor-pointer hidden mq580:flex" onClick={onLocationClick}>
              <div className="relative h-[31.5px] w-[22.6px]">
                <img className="left-[0.5px] h-[31.5px] w-[20.3px] absolute z-10" alt="Pin base" src="/vector.svg" />
                <img className="h-[5.3px] w-[22.6px] absolute bottom-[-4.5px] left-[-1px] z-20" alt="Pin shadow" src="/vector-1.svg" />
              </div>
              <p className="font-subhead ml-2 tracking-tight text-gray hover:text-steelblue focus:text-blue-900">{currentLocation}</p>
            </div>
            <div className="flex flex-row gap-3">
              <div className="hidden w-[80px] mt-1 text-subhead items-center justify-center flex flex-row text-6xl border border-solid border-zinc-300 rounded-full">
                <Download className="pl-1 stroke-blue-900 w-5 h-5" />
                <button className="bg-white font-bold text-black text-[16px] text-subhead text-mq">App</button>
              </div>
              {localStorage.getItem('jwtToken') ?
              <img loading="lazy" src="/Avatar_Default.png" alt="User avatar" className="mt-3 mr-3 h-[30px] w-[30px] cursor-pointer" onClick={onProfileClick} />
              :  
              <button
              className="w-[60px] text-white font-subhead text-[10px] font-semibold pt-[7px] mr-2 h-7 mt-3.5 whitespace-nowrap pb-2 cursor-pointer flex-col justify-center rounded-lg border bg-blue-900 border-blue-900 border-solid hover:border-steelblue"
              onClick={() => navigate("/signup")}
              >
                  Sign Up
              </button>
            }
            </div>
          </div>
        </div>
        <div className="px-3 pt-1">
          <div className="hidden flex-grow pl-2.5 justify-center my-1">
          <SearchBar 
            placeholder="What do you want to do?" 
            iconSrc="/icon--search.svg" 
            id="search-bar" 
            onClick={() => {}}  // This is no longer used, but kept for compatibility
          />
          </div>
        </div>
        <div className="mt-3.5 w-full border border-solid bg-neutral-300 border-neutral-300 min-h-[0.1px] max-md:max-w-full" />
      </div>
      {isMobileDropdownVisible && (
        <div className="mq780:hidden w-[60vw] fixed top-[73px] left-0 right-0 z-40 bg-white">
          <MobileDropdown />
        </div>
      )}
    </>
  );
}

export default Header;