import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BarChartHorizontalIcon, CircleUserRound, AlertCircle } from "lucide-react";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

type MerchantHubSideNavProps = {
  selected: "Home" | "My Deals" | "Analytics" | "Business Info" | "Account";
  onNavigate?: (route: string) => void;
};

const MerchantHubSideNav: React.FC<MerchantHubSideNavProps> = ({ selected, onNavigate }) => {
  const navigate = useNavigate();
  const [analyticsCooldown, setAnalyticsCooldown] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (analyticsCooldown) {
      timer = setTimeout(() => setAnalyticsCooldown(false), 5000);
    }
    return () => clearTimeout(timer);
  }, [analyticsCooldown]);

  const handleStripeAction = async (actionType: 'onboarding' | 'update' | 'dashboard') => {
    if (analyticsCooldown) return;
    setAnalyticsCooldown(true);

    try {
      let response;
      if (actionType === 'dashboard') {
        response = await axios.post(
          `${API_BASE_URL}/v1/merchants/dashboard-link`, 
          {}, 
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
            }
          }
        );
      } else {
        response = await axios.post(
          `${API_BASE_URL}/v1/merchants/account-link`,
          {},
          {
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
              'Content-Type': 'application/json'
            }
          }
        );
      }
  
      if (response.status === 200 && response.data) {
        window.open(response.data, '_blank');
      } else {
        console.error('Invalid response from server');
        setShowAlert(true);
      }
    } catch (error) {
      console.error(`Error fetching Stripe ${actionType} link:`, error);
      setShowAlert(true);
    }
  };

  const handleButtonClick = useCallback((route: string) => {
    if (onNavigate) {
      onNavigate(route);
    } else {
      navigate(route);
    }
  }, [navigate, onNavigate]);

  const getButtonClassNames = useCallback((isActive: boolean) => (
    `flex cursor-pointer gap-5 px-14 py-4 whitespace-nowrap ${
      isActive ? "text-blue-500 bg-white" : "bg-zinc-100"
    }`
  ), []);

  return (
    <>
    <div className="hidden mq780:flex min-h-full flex-col justify-start h-auto mx-0 w-[20vw] text-base font-medium leading-7 min-w-[205px] max-w-[480px] text-zinc-800 bg-zinc-100">
      <div className="flex flex-col pt-10 pb-20 w-full font-subhead">
        <button
          onClick={() => handleButtonClick("/merchant-hub-home")}
          className={getButtonClassNames(selected === "Home")}
        >
          <img
            loading="lazy"
            src={selected === "Home" ? `house-icon-blue.png` : `house-icon-gray.png`}
            className="shrink-0 my-auto w-6 aspect-square"
            alt="Home icon"
          />
          <div className="pt-1">Home</div>
        </button>
        <button
          onClick={() => handleButtonClick("/merchant-hub-deals")}
          className={getButtonClassNames(selected === "My Deals")}
        >
          <img
            loading="lazy"
            src={selected === "My Deals" ? `dollar-icon-blue.png` : `dollar-icon-gray.png`}
            className="shrink-0 my-auto w-6 aspect-square"
            alt="Deals icon"
          />
          <div className="pt-1">My Deals</div>
        </button>
        <button
          onClick={() => handleStripeAction('dashboard')}
          className={`${getButtonClassNames(selected === "Analytics")} ${analyticsCooldown ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={analyticsCooldown}
        >
          <BarChartHorizontalIcon className={`w-5 h-5 ${selected === "Analytics" ? "stroke-[#478abf]" : "stroke-gray-400"}`} />
          <div className="pt-1">Analytics</div>
        </button>
        <button
          onClick={() => handleButtonClick("/merchant-business-info")}
          className={getButtonClassNames(selected === "Business Info")}
        >
          <img
            loading="lazy"
            src={selected === "Business Info" ? `business-icon-blue.png` : `business-icon-gray.png`}
            className="shrink-0 my-auto w-6 aspect-square"
            alt="Business info icon"
          />
          <div className="pt-1">Business Info</div>
        </button>
        <button
          onClick={() => handleButtonClick("/merchant-hub-account")}
          className={getButtonClassNames(selected === "Account")}
        >
          <CircleUserRound className={`w-5 h-5 stroke-width-[3px] ${selected === "Account" ? "stroke-[#478abf]" : "stroke-gray-400"}`} />
          <div className="pt-1">Account</div>
        </button>
      </div>
    </div>
      {showAlert && (
        <div 
          className="fixed left-0 bottom-4 bg-red-500 text-white px-4 py-2 rounded-r-md flex items-center cursor-pointer"
          onClick={() => {
            setShowAlert(false);
            handleButtonClick("/merchant-hub-home");
          }}
        >
          <AlertCircle className="mr-2" />
          Stripe Account Required!
        </div>
      )}
    </>
  );
};

export default React.memo(MerchantHubSideNav);