import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface CategoryResponse {
  id: string;
  name: string;
}

interface Interest {
  id: string;
  name: string;
  icon: string;
}

interface InterestsPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (interests: string[]) => void;
}

const iconMap: { [key: string]: string } = {
  "Restaurant": '/dining-out-icon.png',
  "Cafe": '/dining-out-icon.png',
  "Bar": '/quiz-icon-nightlife.png',
  "Retail": '/shopping-fashion-icon.png',
  "Entertainment": '/theater-icon.png',
  "Sports & Recreation": '/fitness-icon.png',
  "Beauty & Spa": '/quiz-theme-icon-relaxation.png',
  "Education & Learning": '/classes-workshops-icon.png',
  "Outdoor Activities": '/water-activities-icon.png',
  "Arts & Culture": '/museums-icon.png',
  "Nightlife": '/concerts-icon.png',
  "Adventure & Tours": '/quiz-icon-nature.png',
  "Health & Wellness" : '/quiz-theme-icon-relaxation.png'
};

const InterestsPopup: React.FC<InterestsPopupProps> = ({ isOpen, onClose, onSave }) => {
  const [interests, setInterests] = useState<Interest[]>([]);
  const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchInterests = async () => {
      try {
        const response = await axios.get<CategoryResponse[]>(`${API_BASE_URL}/v1/catalog/categories`);
        const fetchedInterests = response.data.map(category => ({
          id: category.id,
          name: category.name,
          icon: iconMap[category.name] || 'default-icon.png'
        }));
        setInterests(fetchedInterests);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to fetch interests. Please try again later.');
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchInterests();
    }
  }, [isOpen]);

  const toggleInterest = useCallback((interestId: string) => {
    setSelectedInterests(prev =>
      prev.includes(interestId)
        ? prev.filter(id => id !== interestId)
        : [...prev, interestId]
    );
  }, []);

  const handleSave = useCallback(() => {
    if (selectedInterests.length >= 1) {
      setIsSaving(true);  // Set saving state to true immediately on click

      // Schedule the button to become clickable again after 3 seconds
      setTimeout(() => {
        setIsSaving(false);
      }, 3000);

      // Perform the actual save operation
      onSave(selectedInterests);
    } else {
      alert("Please select at least one interest.");
    }
  }, [selectedInterests, onSave]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg min-w-[250px] w-[90%] max-w-[min(70vw,500px)] text-start">
        <h2 className="text-xl font-bold mb-4 text-dark-navy">What are your interests?</h2>
        {<p className="_mh780:block text-copy text-xs mb-8 pr-8">
          Please tell us about yourself by selecting up to five interests. This will help us tailor our recommendations to suit your preferences.
        </p>}
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div className="grid grid-cols-3 gap-4 mb-4">
            {interests.map((interest) => (
              <button
                key={interest.id}
                onClick={() => toggleInterest(interest.id)}
                className={`flex cursor-pointer flex-col items-center p-2 border rounded-sm transition-colors duration-200 ${
                  selectedInterests.includes(interest.id) ? 'bg-[#478abf]/20' : 'bg-white'
                }`}
              >
                <img src={interest.icon} alt={interest.name} className="w-8 h-8 mb-2" />
                <span className="text-[9.5px] font-subhead text-main-teal text-center">{interest.name}</span>
              </button>
            ))}
          </div>
        )}
        <div className='w-full items-center justify-center flex py-8'>
          <button
            onClick={handleSave}
            disabled={selectedInterests.length < 1 || isSaving}
            className={`w-[70%] py-2 font-subhead rounded-lg transition-colors duration-200 ${
              selectedInterests.length >= 1 && !isSaving
                ? 'cursor-pointer bg-main-teal text-white'
                : 'bg-zinc-500 text-zinc-300 cursor-not-allowed'
            }`}
          >
            {isSaving ? (
              <>
                <span className="mr-2">Saving interests</span>
                <span className="animate-spin inline-block">&#8987;</span>
              </>
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(InterestsPopup);