import React, { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export type Business = {
  id: string;
  businessName: string;
  categories: { id: string; name: string }[];
  profilePictureReference: string;
};

const ImageSkeleton: React.FC = () => (
  <div className="animate-pulse bg-zinc-300 rounded-full h-[100px] w-[100px]"></div>
);

const BusinessRounderMobile: FunctionComponent<Business> = ({
  id,
  businessName,
  categories,
  profilePictureReference,
}) => {
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleBusinessClick = () => {
    navigate(`/business/${id}`);
  };

  function CategoriesDisplay(categories: { id: string; name: string }[]) {
    return (
      <div>
        {categories.length > 0 && categories.map(cat => cat.name).join(" • ")}
      </div>
    );
  }
  
  useEffect(() => {
    const img = new Image();
    img.src = profilePictureReference || "/business-icon.png";
    img.onload = () => setImageLoaded(true);
    return () => {
      img.onload = null; // Clean up to avoid memory leaks
    };
  }, [profilePictureReference]);
  
  return (
    <div className="snap-center py-2 self-stretch w-[165px] shrink-0 flex flex-col items-center justify-start box-border text-left text-base text-copy font-subhead relative">
      <div className="self-stretch flex-1 flex flex-col items-center justify-start z-[1] relative">
        <div className="relative w-[100px] h-[100px]">
          {!imageLoaded && <ImageSkeleton />}
          <img
            className={`absolute top-0 left-0 w-full h-full object-cover rounded-full transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
            src={profilePictureReference || "/business-icon.png"}
            alt={businessName}
            onLoad={() => setImageLoaded(true)}
            onClick={handleBusinessClick}
          />
        </div>
        <div className="self-stretch flex flex-col items-center justify-start py-2 px-0 gap-[4px]">
          <div
            className="relative text-base tracking-[-0.01em] font-bold text-main-teal cursor-pointer text-center mq450:text-sm"
            onClick={handleBusinessClick}
          >
            {businessName}
          </div>
          <div className="self-stretch text-xs relative font-medium text-center">
            {CategoriesDisplay(categories)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessRounderMobile;