import React, { useState, useRef } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { X } from 'lucide-react';

interface ImageUploaderMultipleProps {
    onImageChange: (imageData: string | undefined) => void;
    maxSize?: number; // in bytes
    currentImages: string[];
    onDeleteImage: (index: number) => void;
    maxImages: number;
}

const ImageUploaderMultiple: React.FC<ImageUploaderMultipleProps> = ({ 
    onImageChange, 
    maxSize = 15 * 1024 * 1024,
    currentImages,
    onDeleteImage,
    maxImages
}) => {
  const [image, setImage] = useState<string | undefined>();
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  const [cropSaved, setCropSaved] = useState(false);
  const [imageSize, setImageSize] = useState<string>('');
  const [fileSizeError, setFileSizeError] = useState<string | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const handleImageUpload = (file: File) => {
    if (currentImages.length >= maxImages) {
      setFileSizeError(`Maximum of ${maxImages} images allowed`);
      return;
    }
    if (file.size > maxSize) {
      setFileSizeError("Image size must not exceed 15MB");
      return;
    }
    if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      setFileSizeError("Image must be of type JPG, PNG, or GIF");
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      setImage(event.target?.result as string);
      setImageSize(calculateImageSize(file));
      setFileSizeError(null);
      setCropSaved(false);
    };
    reader.readAsDataURL(file);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      handleImageUpload(files[0]);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleImageUpload(files[0]);
    }
  };

  const calculateImageSize = (file: File) => {
    const size = file.size / 1024; // Convert to KB
    if (size < 1024) {
      return `${size.toFixed(2)} KB`;
    } else {
      return `${(size / 1024).toFixed(2)} MB`;
    }
  };

  const getCroppedImg = (image: HTMLImageElement, pixelCrop: PixelCrop): Promise<string> => {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
        },
        'image/jpeg',
        1
      );
    });
  };

  const handleCropSave = async () => {
    if (imgRef.current && completedCrop) {
      try {
        const croppedImageUrl = await getCroppedImg(imgRef.current, completedCrop);
        onImageChange(croppedImageUrl);
        setCropSaved(true);
        setImage(undefined); // Reset for next upload
      } catch (e) {
        console.error('Error cropping image:', e);
      }
    }
  };

  const removeImage = () => {
    setImage(undefined);
    setImageSize('');
    setCropSaved(false);
    onImageChange(undefined);
  };

  return (
    <div
      style={{ maxWidth: 'min(624px, 90vw)', minWidth: '200px' }}
      className="flex flex-wrap justify-center items-center px-0 py-5 mt-9 w-inherit text-base leading-7 text-center rounded-md border border-solid border-zinc-800 text-zinc-800 max-md:px-5 max-md:max-w-full relative"
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
    >
      {currentImages.map((img, index) => (
        <div key={index} className="relative w-32 h-32 m-2">
          <img 
            src={img} 
            alt={`Uploaded ${index + 1}`} 
            className="w-full h-full object-cover rounded-md"
          />
          <X 
            onClick={() => onDeleteImage(index)}
            className="absolute top-2 right-2 bg-white rounded-full p-1 cursor-pointer"
          />
        </div>
      ))}
      {currentImages.length < maxImages && (
        image ? (
          <div className="w-full h-full relative flex flex-col">
            {!cropSaved ? (
              <>
                <ReactCrop
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                  className='flex items-center justify-center'
                >
                  <img ref={imgRef} src={image} alt="Upload preview" />
                </ReactCrop>
                <button type="button" onClick={handleCropSave} className="mt-2 bg-blue-500 text-white px-4 py-2 rounded">
                  Save Crop
                </button>
              </>
            ) : (
              <img src={image} alt="Cropped preview" className="w-full h-auto" />
            )}
            <X
              onClick={() => setImage(undefined)}
              className="absolute top-2 right-2 bg-white rounded-full p-1 cursor-pointer"
            />
            {imageSize && (
              <div className="absolute bottom-2 left-2 bg-white rounded px-2 py-1 text-sm">
                {imageSize}
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col max-w-full w-[186px]">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6c7c97d7821c53ae0a76f061fb2c2ac41d67592fc162ea0f98664d14dd49d52?apiKey=0d607e805d95499aa95fa0a93ed5f083&"
              alt="Upload icon"
              className="self-center w-12 aspect-square"
            />
            <div className="mt-6">Drag & drop a photo here or click to upload</div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="hidden"
              id="fileInput"
            />
            <label htmlFor="fileInput" className="mt-4 cursor-pointer text-blue-500 underline">
              Browse files
            </label>
          </div>
        )
      )}
      {fileSizeError && <div className="text-red-500 text-sm mt-1">{fileSizeError}</div>}
    </div>
  );
};

export default ImageUploaderMultiple;